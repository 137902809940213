import { RestApiError } from 'error/restError';

import axios from 'axios';
import qs from 'qs';
import cookie from 'helpers/cookie';

export function gaAuthRevoke() {
	const data = {};

	return axios({
		method: 'get',
		url: `${process.env.REACT_APP_DOMAIN}/ga_auth/revoke`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data,
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}

export function gaAuthCheck() {
	const data = {};

	return axios({
		method: 'get',
		url: `${process.env.REACT_APP_GA_API}/analytics/check_auth/`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data,
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}

export function getAccountSummaries() {
	const data = {};

	return axios({
		method: 'get',
		url: `${process.env.REACT_APP_GA_API}/analytics/account_summaries/`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data,
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}

export function getCustomDataSources(params) {
	const { accountId, webPropertyId } = params;

	if (!accountId || !webPropertyId)
		throw new RestApiError('accountId, webPropertyIdshould be specify');

	const data = { accountId, webPropertyId };

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_GA_API}/analytics/custom_data_sources/`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data: qs.stringify(data),
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}

export function getGoalsList(params) {
	const { accountId, webPropertyId, profileId } = params;

	if (!accountId || !webPropertyId || !profileId)
		throw new RestApiError(
			'accountId, webPropertyId, profileId should be specify',
		);

	const data = { accountId, webPropertyId, profileId };

	return axios({
		method: 'post',
		url: `${process.env.REACT_APP_GA_API}/analytics/goals_list/`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `bearer ${cookie.token()}`,
		},
		data: qs.stringify(data),
	}).then(response => {
		if (!response.data.success) {
			throw new RestApiError(response.data);
		}

		return response.data.data;
	});
}
