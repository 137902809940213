import { RestApiError } from 'error/restError';

export function placementsGet() {
	return new Promise(function (resolve, reject) {
		window.BX24.callMethod('placement.get', {}, function (result) {
			if (result.error()) reject(new RestApiError(result.error()));
			else resolve(result.data());
		});
	});
}

export function placementBind(placement) {
	return new Promise(function (resolve, reject) {
		window.BX24.callMethod(
			'placement.bind',
			{
				HANDLER: `${process.env.REACT_APP_B242GA_PLACEMENT_HANDLER}`,
				PLACEMENT: placement,
				TITLE: 'B242YA',
				DESCRIPTION: '',
			},
			function (result) {
				if (result.error()) reject(new RestApiError(result.error()));
				else resolve(result.data());
			},
		);
	});
}

export function placementUnbind(placement) {
	return new Promise(function (resolve, reject) {
		window.BX24.callMethod(
			'placement.unbind',
			{
				HANDLER: `${process.env.REACT_APP_B242GA_PLACEMENT_HANDLER}`,
				PLACEMENT: placement,
			},
			function (result) {
				if (result.error()) reject(new RestApiError(result.error()));
				else resolve(result.data());
			},
		);
	});
}
