import { call, put, take, fork } from 'redux-saga/effects';

import {
  getAccountSummaries,
  getCustomDataSources,
  getGoalsList,
} from 'api/ga';

import {
  gaAccountSummariesFetchSuccess,
  gaAccountSummariesFetchError,
  GA_ACCOUNT_SUMMARIES_FETCH_START,
  gaCustomDataSourcesFetchSuccess,
  gaCustomDataSourcesFetchError,
  GA_CUSTOM_DATA_SOURCES_FETCH_START,
  gaGoalsListFetchSuccess,
  gaGoalsListFetchError,
  GA_GOALS_LIST_FETCH_START,
} from 'actions/gaActions';

export function* gaAccountSummariesFlow() {
  while (true) {
    yield take(GA_ACCOUNT_SUMMARIES_FETCH_START);

    try {
      const data = yield call(getAccountSummaries);
      yield put(gaAccountSummariesFetchSuccess(data));
    } catch (error) {
      yield put(gaAccountSummariesFetchError(error));
    }
  }
}

export function* gaCustomDataSourcesFlow() {
  while (true) {
    const action = yield take(GA_CUSTOM_DATA_SOURCES_FETCH_START);

    try {
      const data = yield call(getCustomDataSources, action.payload);
      yield put(gaCustomDataSourcesFetchSuccess(data));
    } catch (error) {
      yield put(gaCustomDataSourcesFetchError(error));
    }
  }
}

function* goals(payload) {
  try {
    const data = yield call(getGoalsList, payload);
    yield put(gaGoalsListFetchSuccess(data));
  } catch (error) {
    yield put(gaGoalsListFetchError(error));
  }
}

export function* gaGoalsListFlow() {
  while (true) {
    const action = yield take(GA_GOALS_LIST_FETCH_START);
    const data = yield fork(goals, action.payload);
  }
}
