import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import parse from 'html-react-parser';
/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import PanToolIcon from '@material-ui/icons/PanTool';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Backdrop from '@material-ui/core/Backdrop';
import Link from '@material-ui/core/Link';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';
import { AlertDialog } from 'components/common/Dialog';

/** Sub components */
import Goal from './Goal';

/** Actions */
import {
	gaAccountSummariesFetchStart,
	gaGoalsListFetchStart,
} from 'actions/gaActions';

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

/** API */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	chipContainer: {
		width: '500px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	goalsList: {
		paddingLeft: 30,
	},
}));

const GaProfile = ({
	SettingsModel,
	settingKeyStages,
	settingKeyCategories,
	stageSave,
	isFetching,
	loadingState,
	setLoadingState,
	categoryId,
	categoryList,
	stageList,
	stagesReset,
	expertMode,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);

	const [gaAccountIdState, setGaAccountIdState] = useState(false);
	const [webPropertyIdState, setWebPropertyIdState] = useState(false);
	const [internalWebPropertyIdState, setInternalWebPropertyIdState] = useState(
		false,
	);
	const [profileIdState, setPropfileIdState] = useState(false);

	const [alertDialgState, setAlertDialogState] = useState({
		open: false,
		title: '',
		description: '',
		agreeHandler: () => { },
		closeHandler: () => { },
	});

	const fetchAccountSummaries = () =>
		dispatch(gaAccountSummariesFetchStart({}));

	const fetchGoalsList = params => dispatch(gaGoalsListFetchStart(params));

	useEffect(() => {
		fetchAccountSummaries();
		console.log('use effect fetch');
	}, [dispatch]);

	const currentCategory = useMemo(() => {
		return (
			SettingsModel[settingKeyCategories].find(
				category => category.ID == categoryId,
			) || {}
		);
	}, [SettingsModel]);

	const gaAccountIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_ID]
			: false;
	}, [SettingsModel]);

	const webPropertyIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID]
			: false;
	}, [SettingsModel]);

	const internalWebPropertyIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID]
			: false;
	}, [SettingsModel]);

	const profileIdSettings = useMemo(() => {
		return CONFIG.GA_ACCOUNT_PROFILE_ID in currentCategory
			? currentCategory[CONFIG.GA_ACCOUNT_PROFILE_ID]
			: false;
	}, [SettingsModel]);

	const accountSummaries = useSelector(state => state.ga_account_summaries);
	const goalsIsFetching = useSelector(state => state.ga_goals.fetching);

	const webPropertiesList = useSelector(state => {
		if (
			'items' in state.ga_account_summaries &&
			state.ga_account_summaries.items.length > 0 &&
			gaAccountIdSettings
		) {
			const currentAccount =
				state.ga_account_summaries.items.find(
					account => account.id == gaAccountIdSettings,
				) || {};

			if ('webProperties' in currentAccount)
				return currentAccount.webProperties;
		}

		return [];
	});

	const profileList = useSelector(state => {
		if (
			'items' in state.ga_account_summaries &&
			state.ga_account_summaries.items.length > 0 &&
			gaAccountIdSettings &&
			webPropertyIdSettings
		) {
			const webProperties =
				state.ga_account_summaries.items.find(
					account => account.id == gaAccountIdSettings,
				).webProperties || [];

			const currentWebProperty =
				webProperties.find(property => property.id == webPropertyIdSettings) ||
				{};

			if ('profiles' in currentWebProperty) return currentWebProperty.profiles;
		}

		return [];
	});

	const goalsList = useSelector(state =>
		state.ga_goals.items.filter(goal => goal.profileId == profileIdSettings),
	);

	useEffect(() => {
		if (gaAccountIdSettings && webPropertyIdSettings && profileIdSettings) {
			fetchGoalsList({
				accountId: gaAccountIdSettings,
				webPropertyId: webPropertyIdSettings,
				profileId: profileIdSettings,
			});
		}
	}, [gaAccountIdSettings, webPropertyIdSettings, profileIdSettings]);

	const reloadGoals = useCallback(() => {
		fetchGoalsList({
			accountId: gaAccountIdSettings,
			webPropertyId: webPropertyIdSettings,
			profileId: profileIdSettings,
		});
	});

	useEffect(() => {
		const isSomeFetching = [
			isFetching('categoryGaAccount', categoryId),
			isFetching('categoryWebProperty', categoryId),
			isFetching('categoryProfile', categoryId),
		].some(fetching => fetching);

		if (gaAccountIdSettings && stepEditState === 1 && !isSomeFetching) {
			setActiveStep(1);
		}

		if (webPropertyIdSettings && stepEditState === 2 && !isSomeFetching) {
			setActiveStep(2);
		}

		if (profileIdSettings && stepEditState === 3 && !isSomeFetching) {
			setActiveStep(3);
		}

		if (gaAccountIdSettings && !webPropertyIdSettings && !stepEditState) {
			setActiveStep(1);
		}

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			!profileIdSettings &&
			!stepEditState
		)
			setActiveStep(2);

		if (
			gaAccountIdSettings &&
			webPropertyIdSettings &&
			profileIdSettings &&
			!stepEditState
		)
			setActiveStep(3);

		if (stepEditState === 0) setActiveStep(0);
	});

	const gaAccountIdEdit = useCallback(event => {
		setGaAccountIdState(event.target.value);
	});

	const webPropertyIdEdit = useCallback(event => {
		setWebPropertyIdState(event.target.value);
		const { internalWebPropertyId = false } = webPropertiesList.find(
			webProperty => webProperty.id === event.target.value,
		);
		setInternalWebPropertyIdState(internalWebPropertyId);
	});

	const profileIdEdit = useCallback(event => {
		setPropfileIdState(event.target.value);
	});

	const allStepsCompleted = useMemo(() => {
		return gaAccountIdSettings && webPropertyIdSettings && profileIdSettings;
	}, [gaAccountIdSettings, profileIdSettings]);

	const withAlertAllGoalsReset = handler =>
		withAlertDialog(
			handler,
			allStepsCompleted,
			t('Attention!'),
			t('All relation between goals and stages will be deleted'),
		);

	const withAlertDialog = (agreeHandler, condition, title, description) => {
		return () => {
			if (condition) {
				setAlertDialogState({
					open: true,
					title,
					agreeHandler,
					description,
					closeHandler: setAlertDialogState.bind(this, {
						open: false,
						title: '',
						description: '',
						agreeHandler: () => { },
						closeHandler: () => { },
					}),
				});
			} else {
				agreeHandler();
			}
		};
	};

	const gaAccountIdSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_ACCOUNT_ID]: gaAccountIdState,
			[CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID]: false,
			[CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID]: false,
			[CONFIG.GA_ACCOUNT_PROFILE_ID]: false,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		/** Reset next step values */
		setWebPropertyIdState(false);
		setPropfileIdState(false);

		setLoadingState({
			...loadingState,
			categoryGaAccount: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(1);
	};

	const webProperyIdSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_ACCOUNT_WEB_PROPERTY_ID]: webPropertyIdState,
			[CONFIG.GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID]: internalWebPropertyIdState,
			[CONFIG.GA_ACCOUNT_PROFILE_ID]: false,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		/** Reset next step values */
		setPropfileIdState(false);

		setLoadingState({
			...loadingState,
			categoryWebProperty: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(2);
	};

	const profileIdSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.GA_ACCOUNT_PROFILE_ID]: profileIdState,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		setLoadingState({
			...loadingState,
			categoryProfile: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(3);
	};

	const backToAccount = () => {
		setStepEditState(0);
	};

	const backToWebProperty = () => {
		setStepEditState(1);
	};

	const backToProfile = () => {
		setStepEditState(2);
	};

	const backToGoals = () => {
		setStepEditState(3);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const linkGoalsEditHref = useMemo(() => {
		if (
			gaAccountIdSettings &&
			internalWebPropertyIdSettings &&
			profileIdSettings
		)
			return `https://analytics.google.com/analytics/web/#/a${gaAccountIdSettings}w${internalWebPropertyIdSettings}p${profileIdSettings}/admin/goals/table`;

		return null;
	}, [gaAccountIdSettings, internalWebPropertyIdSettings, profileIdSettings]);

	const LinkGoalsEdit = useCallback(
		() => (
			<>
				{linkGoalsEditHref ? (
					<Link target="_blank" href={linkGoalsEditHref}>
						✏️ {t('Edit goals in Google Analytics account')}
					</Link>
				) : null}
			</>
		),
		[linkGoalsEditHref],
	);

	return (
		<div className={classes.root}>
			<AlertDialog {...alertDialgState} />
			<Stepper activeStep={activeStep} orientation="vertical">
				<Step key={0}>
					<StepLabel>
						{gaAccountIdSettings &&
							accountSummaries.items.some(
								account => account.id == gaAccountIdSettings,
							) ? (
							<StepLabel>
								{
									accountSummaries.items.find(
										account => account.id == gaAccountIdSettings,
									)['name']
								}
								{gaAccountIdSettings && activeStep > 0 ? (
									<Button onClick={backToAccount}>
										<EditIcon fontSize="small" />
									</Button>
								) : null}
							</StepLabel>
						) : (
							<StepLabel>{t('Google Analytics Account')}</StepLabel>
						)}
					</StepLabel>
					<StepContent>
						<FormControl variant="outlined">
							<InputLabel htmlFor="outlined-age-native-simple">
								{'account'}
							</InputLabel>
							<Select
								label={'account'}
								onChange={gaAccountIdEdit}
								value={
									gaAccountIdState !== false
										? gaAccountIdState
										: gaAccountIdSettings
											? gaAccountIdSettings
											: 'default'
								}
								style={{ width: 390 }}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value="default">Default</MenuItem>
								{accountSummaries.items.map((account, i) => (
									<MenuItem value={account.id} key={i}>
										{account.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<div className={classes.actionsContainer}>
							<div>
								<Button
									variant="contained"
									color="primary"
									onClick={withAlertAllGoalsReset(gaAccountIdSave)}
									className={classes.button}
									disabled={
										!gaAccountIdState ||
										gaAccountIdState == gaAccountIdSettings ||
										gaAccountIdState == 'default' ||
										isFetching('categoryGaAccount', categoryId)
									}
								>
									{t('Save')}
									<SaveIcon />
									{isFetching('categoryGaAccount', categoryId) ? (
										<CircularProgressStyled size={24} />
									) : null}
								</Button>
							</div>
						</div>
					</StepContent>
				</Step>
				<Step key={1}>
					{webPropertyIdSettings &&
						webPropertiesList.some(
							webProperty => webProperty.id == webPropertyIdSettings,
						) ? (
						<StepLabel>
							{
								webPropertiesList.find(
									webProperty => webProperty.id == webPropertyIdSettings,
								)['id']
							}
							{webPropertyIdSettings && activeStep !== 1 ? (
								<Button onClick={backToWebProperty}>
									<EditIcon fontSize="small" />
								</Button>
							) : null}
						</StepLabel>
					) : (
						<StepLabel>{t('Select counter')}</StepLabel>
					)}

					<StepContent>
						<FormControl variant="outlined">
							<InputLabel htmlFor="outlined-age-native-simple">
								{'counter'}
							</InputLabel>
							<Select
								label={'counter'}
								style={{ width: 390 }}
								onChange={webPropertyIdEdit}
								inputProps={{ 'aria-label': 'Without label' }}
								value={
									webPropertyIdState !== false
										? webPropertyIdState
										: webPropertyIdSettings
											? webPropertyIdSettings
											: 'default'
								}
							>
								<MenuItem value="default">Default</MenuItem>
								{webPropertiesList.map((webProperty, i) => (
									<MenuItem value={webProperty.id} key={webProperty.id}>
										{`${webProperty.name} (${webProperty.id})`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<div className={classes.actionsContainer}>
							<div>
								<Button
									variant="contained"
									color="primary"
									onClick={withAlertAllGoalsReset(webProperyIdSave)}
									className={classes.button}
									disabled={
										!webPropertyIdState ||
										webPropertyIdState == webPropertyIdSettings ||
										webPropertyIdState == 'default' ||
										isFetching('categoryWebProperty', categoryId)
									}
								>
									{t('Save')}
									<SaveIcon />
									{isFetching('categoryWebProperty', categoryId) ? (
										<CircularProgressStyled size={24} />
									) : null}
								</Button>
							</div>
						</div>
					</StepContent>
				</Step>
				<Step key={2} disabled>
					{profileIdSettings &&
						profileList.some(profile => profile.id == profileIdSettings) ? (
						<StepLabel>
							{
								profileList.find(profile => profile.id == profileIdSettings)[
								'name'
								]
							}
							{profileIdSettings && activeStep !== 2 ? (
								<Button onClick={backToProfile}>
									<EditIcon fontSize="small" />
								</Button>
							) : null}
						</StepLabel>
					) : (
						<StepLabel>{t('Select view')}</StepLabel>
					)}
					<StepContent>
						<FormControl variant="outlined">
							<InputLabel htmlFor="outlined-age-native-simple">
								{'view'}
							</InputLabel>
							<Select
								label={'view'}
								style={{ width: 390 }}
								onChange={profileIdEdit}
								inputProps={{ 'aria-label': 'Without label' }}
								value={
									profileIdState !== false
										? profileIdState
										: profileIdSettings
											? profileIdSettings
											: 'default'
								}
							>
								<MenuItem value="default">Default</MenuItem>
								{profileList.map((profile, i) => (
									<MenuItem value={profile.id} key={profile.id}>
										{`${profile.name}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<div className={classes.actionsContainer}>
							<div>
								<Button
									variant="contained"
									color="primary"
									onClick={withAlertAllGoalsReset(profileIdSave)}
									className={classes.button}
									disabled={
										!profileIdState ||
										profileIdState == profileIdSettings ||
										profileIdState == 'default' ||
										isFetching('categoryProfile', categoryId)
									}
								>
									{t('Save')}
									<SaveIcon />
									{isFetching('categoryProfile', categoryId) ? (
										<CircularProgressStyled size={24} />
									) : null}
								</Button>
							</div>
						</div>
					</StepContent>
				</Step>
				<Step key={3}>
					<StepLabel>
						{t('Goals choise')}
						{goalsList.length && activeStep !== 3 ? (
							<Button onClick={backToGoals}>
								<EditIcon fontSize="small" />
							</Button>
						) : (
							<>
								<Button
									onClick={reloadGoals}
									disabled={goalsIsFetching || activeStep !== 3}
								>
									<CachedIcon />
									{goalsIsFetching ? (
										<CircularProgressStyled size={24} />
									) : null}
								</Button>
								<LinkGoalsEdit />
							</>
						)}
					</StepLabel>
				</Step>
			</Stepper>
			<div className={classes.goalsList}>
				{goalsList.map((goal, i) => (
					<Goal
						SettingsModel={SettingsModel}
						settingKey={'dealsStages'}
						goal={goal}
						key={i}
						stageSave={stageSave}
					/>
				))}
				{goalsList.length == 0 ? t('There is no goals in this view') : null}
			</div>
		</div>
	);
};

export default GaProfile;
