import React from 'react';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

const Main = props => {
	const history = useHistory();
	const { t } = useTranslationWithParse();
	const handleClick = link => history.push(link);
	const currPath = props.children.props.location.pathname;

	const menuItems = [
		{
			path: '/deals',
			label: `✍️ ${t('M.DEALS')}`,
			selected: currPath == '/deals' ? true : false,
			disabled: false,
		},
		{
			path: '/leads',
			label: `🤙 ${t('M.LEADS')}`,
			selected: currPath == '/leads' ? true : false,
			disabled: false,
		},
		{
			path: '/settings',
			label: `⚙️ ${t('M.SETTINGS')}`,
			selected: currPath == '/settings' ? true : false,
			disabled: false,
		},
		{
			path: '/call-tracking',
			label: `☎️ ${t('M.CALL-T')}`,
			selected: currPath == '/call-tracking' ? true : false,
			disabled: false,
		},
		{
			path: '/email-tracking',
			label: `📨 ${t('M.EMAIL-T')}`,
			selected: currPath == '/email-tracking' ? true : false,
			disabled: false,
		},
		{
			path: '/logs',
			label: `🧻 ${t('M.LOGS')}`,
			selected: currPath == '/logs' ? true : false,
			disabled: false,
		},
		{
			path: '/datalens',
			label: `📊 Выгрузка данных в БД`,
			selected: currPath == '/datalens' ? true : false,
			disabled: false,
		},
	
		/*
		{
			path: '/costs',
			label: `💳 ${t('M.COSTS')}`,
			selected: currPath == '/costs' ? true : false,
			disabled: false,
		},
		*/
		/*
		{
			path: '/generator',
			label: `☀️ ${t('M.GENERATOR')}`,
			selected: currPath == '/generator' ? true : false,
		},
		{
			path: '/datalens',
			label: `📊 ${t('M.DATALENS')}`,
			selected: currPath == '/datalens' ? true : false,
			disabled: true,
		},
		*/
	];

	const printMenu = () => {
		let selectedKey = 0;

		menuItems.forEach(
			(item, i) => (selectedKey = item.selected ? i : selectedKey),
		);

		return (
			<>
				<Tabs value={selectedKey} style={{ width: '100%' }}>
					{menuItems.map((item, i) => (
						<>
							<Tab
								label={item.label}
								key={i}
								onClick={handleClick.bind(null, item.path)}
								disabled={item.disabled}
							/>
						</>
					))}
				</Tabs>
				<Button
					color="primary"
					variant="outlined"
					onClick={() => window.open('https://t.me/B24Anayltics', '_blank')}
				>
					📨
					<b>
						ТГ-канал B242YA - только полезная информация (релизы, обновления,
						описание функционала)
					</b>
				</Button>
			</>
		);
	};

	return (
		<Container
			style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '100%' }}
		>
			<AppBar position="fixed" color="default">
				{printMenu()}
			</AppBar>
			<br />
			<br />
			<br />
			<br />
			<br />
			<div style={{ padding: 24, background: '#ffffff' }}>{props.children}</div>
			<br />
			<br />
			<br />
			{/*
			<AppBar position="fixed" style={{ bottom: 20, top: 'auto', height:'70px' }} color="default">
				<center><Button color="secondary" variant="contained" onClick={() => window.open('https://b242ya.ru/intergation', '_blank')}>🔥<b>Подробнее о бесплатной интеграции B242YA</b>🔥</Button><br />Интеграция от разработчика - от анализа вашего проекта до маркетинговых отчетов.  </center>
			</AppBar>	
			*/}
			<span onClick={handleClick.bind(null, '/datalens')}>&nbsp;&nbsp;.</span>
		</Container>
	);
};

export default Main;
