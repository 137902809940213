import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import parse from 'html-react-parser';
/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import PanToolIcon from '@material-ui/icons/PanTool';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Backdrop from '@material-ui/core/Backdrop';
import Link from '@material-ui/core/Link';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';
import { AlertDialog } from 'components/common/Dialog';

/** Sub components */
import YaGoal from './YaGoal';

/** Actions */
import {
	gaAccountSummariesFetchStart,
	gaGoalsListFetchStart,
} from 'actions/gaActions';

import {
	yaCountersFetchStart,
	yaGoalsFetchStart,
	yaGoalsReset,
} from 'actions/yaActions';

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

/** API */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	chipContainer: {
		width: '500px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	goalsList: {
		paddingLeft: 30,
	},
}));

const YaProfile = ({
	SettingsModel,
	settingKeyStages,
	settingKeyCategories,
	stageSave,
	isFetching,
	loadingState,
	setLoadingState,
	categoryId,
	categoryList,
	stageList,
	stagesReset,
	expertMode,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);

	const [yaCounterIdState, setYaCounterIdState] = useState(false);
	const [yaCounterNameState, setYaCounterNameState] = useState(false);

	const [alertDialgState, setAlertDialogState] = useState({
		open: false,
		title: '',
		description: '',
		agreeHandler: () => { },
		closeHandler: () => { },
	});

	const fetchYaCounters = () => dispatch(yaCountersFetchStart({}));
	const fetchGoalsList = params => dispatch(yaGoalsFetchStart(params));
	const resetGoals = () => dispatch(yaGoalsReset());

	useEffect(() => {
		fetchYaCounters();
		resetGoals();
	}, [dispatch]);

	const currentCategory = useMemo(() => {
		return (
			SettingsModel[settingKeyCategories].find(
				category => category.ID == categoryId,
			) || {}
		);
	}, [SettingsModel]);

	const yaCounterIdSettings = useMemo(() => {
		return CONFIG.YA_COUNTER_ID in currentCategory
			? currentCategory[CONFIG.YA_COUNTER_ID]
			: false;
	}, [SettingsModel]);

	const yaCounters = useSelector(state => state.ya_counters);

	const counters = useMemo(() => {
		return yaCounters?.items?.data?.counters
			? yaCounters.items.data.counters
			: [];
	}, [yaCounters]);

	const goalsIsFetching = useSelector(state => state.ya_goals.fetching);
	const goalsList = useSelector(state => state.ya_goals);

	const goals = useMemo(() => {
		return goalsList?.items?.data?.goals ? goalsList.items.data.goals : [];
	}, [goalsList]);

	useEffect(() => {
		if (yaCounterIdSettings) {
			fetchGoalsList({
				counter: yaCounterIdSettings,
			});

			//if (stepEditState !== 1) setActiveStep(1);
			if (stepEditState !== 0) setActiveStep(1);
			if (stepEditState === 0) setActiveStep(0);
		}
	}, [yaCounterIdSettings, stepEditState]);

	const reloadGoals = useCallback(() => {
		if (yaCounterIdSettings) {
			fetchGoalsList({
				counter: yaCounterIdSettings,
			});
		}
	}, [yaCounterIdSettings]);

	useEffect(() => {
		const isSomeFetching = [isFetching('categoryYaAccount', categoryId)].some(
			fetching => fetching,
		);

		if (yaCounterIdSettings && stepEditState === 1 && !isSomeFetching) {
			setActiveStep(1);
		}
	});

	const yaCounterIdEdit = useCallback(event => {
		const { yaCounterName } = event.currentTarget.dataset;

		setYaCounterIdState(event.target.value);
		setYaCounterNameState(yaCounterName);
	});

	const allStepsCompleted = useMemo(() => {
		return !!yaCounterIdSettings;
	}, [yaCounterIdSettings]);

	const withAlertAllGoalsReset = handler =>
		withAlertDialog(
			handler,
			allStepsCompleted,
			t('Attention!'),
			t('All relation between goals and stages will be deleted'),
		);

	const withAlertDialog = (agreeHandler, condition, title, description) => {
		return () => {
			if (condition) {
				setAlertDialogState({
					open: true,
					title,
					agreeHandler,
					description,
					closeHandler: setAlertDialogState.bind(this, {
						open: false,
						title: '',
						description: '',
						agreeHandler: () => { },
						closeHandler: () => { },
					}),
				});
			} else {
				agreeHandler();
			}
		};
	};

	const yaCounterIdSave = () => {
		SettingsModel[settingKeyCategories] = {
			ID: categoryId,
			[CONFIG.YA_COUNTER_ID]: yaCounterIdState,
			[CONFIG.YA_COUNTER_NAME]: yaCounterNameState,
		};

		if (allStepsCompleted) {
			let listForDelete = [];
			for (let { STATUS_ID } of stageList.items) {
				listForDelete.push(STATUS_ID);
			}
			stagesReset(listForDelete);
		}

		setLoadingState({
			...loadingState,
			categoryYaAccount: { [categoryId]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(1);
	};

	const backToAccount = () => {
		setStepEditState(0);
	};

	const backToGoals = () => {
		setStepEditState(0);
	};

	const linkGoalsEditHref = useMemo(() => {
		if (yaCounterIdSettings)
			return `https://metrika.yandex.ru/goals?id=${yaCounterIdSettings}`;

		return null;
	}, [yaCounterIdSettings]);

	const LinkGoalsEdit = useCallback(
		() => (
			<>
				{linkGoalsEditHref ? (
					<Link target="_blank" href={linkGoalsEditHref}>
						✏️ {t('Edit goals in Google Analytics account')}
					</Link>
				) : null}
			</>
		),
		[linkGoalsEditHref],
	);

	return (
		<div className={classes.root}>
			<AlertDialog {...alertDialgState} />
			<Stepper activeStep={activeStep} orientation="vertical">
				<Step key={0}>
					<StepLabel>
						{yaCounterIdSettings &&
							counters.some(account => account.id == yaCounterIdSettings) ? (
							<StepLabel>
								{
									counters.find(account => account.id == yaCounterIdSettings)[
									'name'
									]
								}
								{yaCounterIdSettings && activeStep > 0 ? (
									<Button onClick={backToAccount}>
										<EditIcon fontSize="small" />
									</Button>
								) : null}
							</StepLabel>
						) : (
							<StepLabel>{t('Google Analytics Account')}</StepLabel>
						)}
					</StepLabel>
					<StepContent>
						<FormControl variant="outlined">
							<InputLabel htmlFor="outlined-age-native-simple">
								{'account'}
							</InputLabel>
							<Select
								label={'account'}
								onChange={yaCounterIdEdit}
								value={
									yaCounterIdState !== false
										? yaCounterIdState
										: yaCounterIdSettings
											? yaCounterIdSettings
											: 'default'
								}
								style={{ width: 390 }}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value="default">Default</MenuItem>
								{counters.map((account, i) => (
									<MenuItem
										value={account.id}
										key={i}
										data-ya-counter-name={account.name}
									>
										{account.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<div className={classes.actionsContainer}>
							<div>
								<Button
									variant="contained"
									color="primary"
									onClick={withAlertAllGoalsReset(yaCounterIdSave)}
									className={classes.button}
									disabled={
										!yaCounterIdState ||
										yaCounterIdState == yaCounterIdSettings ||
										yaCounterIdState == 'default' ||
										isFetching('categoryYaAccount', categoryId)
									}
								>
									{t('Save')}
									<SaveIcon />
									{isFetching('categoryYaAccount', categoryId) ? (
										<CircularProgressStyled size={24} />
									) : null}
								</Button>
							</div>
						</div>
					</StepContent>
				</Step>
				<Step key={1}>
					<StepLabel>
						{t('Goals choise')}
						{goals.length && activeStep !== 1 ? (
							<Button onClick={backToGoals}>
								<EditIcon fontSize="small" />
							</Button>
						) : (
							<>
								<Button
									onClick={reloadGoals}
									disabled={goalsIsFetching || activeStep !== 1}
								>
									<CachedIcon />
									{goalsIsFetching ? (
										<CircularProgressStyled size={24} />
									) : null}
								</Button>
								<LinkGoalsEdit />
							</>
						)}
					</StepLabel>
				</Step>
			</Stepper>
			<div className={classes.goalsList}>
				{' '}
				{goals.map((goal, i) => (
					<YaGoal
						SettingsModel={SettingsModel}
						goal={goal}
						key={i}
						stageSave={stageSave}
					/>
				))}
				{goals.length == 0 ? t('There is no goals in this view') : null}
			</div>
		</div>
	);
};

export default YaProfile;
