import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

/** Custom components */
import { AlertDialog } from 'components/common/Dialog';

/** Actions */
import { authRevokeYaFetchStart } from 'actions/authActions';
import { authCheckYaFetchStart } from 'actions/authActions';
import { settingsUpdateStart } from 'actions/settingsActions';

/** Helpers */
import CONFIG from 'helpers/const';
import { Settings } from 'models/settings';
import { handleYaAuthWindow } from 'helpers/auth';
import { useTranslationWithParse } from 'helpers/translation';

const YaAuth = () => {
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const settingsList = useSelector(state => state.settings);

	const SettingsModel = new Settings(settingsList, {
		update: payload => dispatch(settingsUpdateStart(payload)),
	});

	const [warningDialogOpenState, setWarningDialogOpenState] = useState(false);

	const revokeYaAuthFetch = () => dispatch(authRevokeYaFetchStart({}));

	const fetchAuthCheck = () => dispatch(authCheckYaFetchStart({}));

	const authList = useSelector(state => state.auth);

	useEffect(() => {
		fetchAuthCheck();
	}, []);

	const handleRevokeYaAuth = useCallback(() => {
		SettingsModel.costDeleteBySource(CONFIG.COST_SOURCE_YANDEX);
		SettingsModel.saveSettings();
		revokeYaAuthFetch();
	}, []);

	const handleWarningDialogOpen = useCallback(() => {
		setWarningDialogOpenState(true);
	}, []);

	const handleWarningDialogClose = useCallback(() => {
		setWarningDialogOpenState(false);
	}, []);

	const openYaAuth = useCallback(() => {
		handleYaAuthWindow(fetchAuthCheck);
	}, []);

	return (
		<>
			<AlertDialog
				open={warningDialogOpenState}
				title={`☠️ ${t('Attention!')}`}
				description={t('The Yandex authorization will be removed')}
				agreeHandler={handleRevokeYaAuth}
				closeHandler={handleWarningDialogClose}
			/>
			{authList.ya === true ? (
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleWarningDialogOpen}
				>
					<Avatar src="/resources/YaLogo.png" />
					&nbsp;{t('Delete YA auth')}
				</Button>
			) : (
				<Button variant="outlined" color="primary" onClick={openYaAuth}>
					<Avatar src="/resources/YaLogo.png" />
					&nbsp;{t('Login')}
				</Button>
			)}
		</>
	);
};

export default YaAuth;
