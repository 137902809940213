import {
	SETTINGS_GET_START,
	SETTINGS_GET_SUCCESS,
	SETTINGS_GET_ERROR,
	SETTINGS_INIT_START,
	SETTINGS_INIT_ERROR,
	SETTINGS_INIT_SUCCESS,
	SETTINGS_UPDATE_START,
	SETTINGS_UPDATE_SUCCESS,
	SETTINGS_UPDATE_ERROR,
} from 'actions/settingsActions';

export const initialState = {
	deals_stages: [],
	deals_category: [],
	deals_ga_cid_field: '',
	deals_ga_uid_field: 'default',
	deals_ga_counter_field: 'default',
	deals_cd: [],
	leads_stages: [],
	leads_category: [],
	leads_ga_cid_field: '',
	leads_ga_uid_field: 'default',
	leads_ga_counter_field: 'default',
	leads_cd: [],
	sources: [],
	email_tracking: [],
	call_tracking: [],
	costs: [],
	cid_generator: false,
	ga_counter: '',
	track_id: false,
	notifications: true,
	data_lens_settings: {
		dbHost: '',
		dbPort: '',
		dbName: '',
		dbLogin: '',
		dbPass: '',
	},
};

export default (state = { ...initialState, fetching: false }, action) => {
	switch (action.type) {
		case SETTINGS_INIT_START:
			return {
				...state,
				fetching: true,
			};
		case SETTINGS_INIT_SUCCESS:
			return {
				...state,
				fetching: false,
			};
		case SETTINGS_INIT_ERROR:
			return {
				...state,
				fetching: false,
			};
		case SETTINGS_GET_START:
			return {
				...state,
				fetching: true,
			};
		case SETTINGS_GET_SUCCESS:
			return {
				...state,
				deals_stages: [...action.payload.deals_stages],
				deals_category: [...action.payload.deals_category],
				deals_ga_cid_field: action.payload.deals_ga_cid_field || '',
				deals_ga_uid_field: action.payload.deals_ga_uid_field || '',
				deals_ga_counter_field: action.payload.deals_ga_counter_field || '',
				deals_cd: action.payload.deals_cd || [],

				leads_stages: [...action.payload.leads_stages],
				leads_category: [...action.payload.leads_category],
				leads_ga_cid_field: action.payload.leads_ga_cid_field || '',
				leads_ga_uid_field: action.payload.leads_ga_uid_field || '',
				leads_ga_counter_field: action.payload.leads_ga_counter_field || '',
				leads_cd: action.payload.leads_cd || [],

				sources: action.payload.sources || [],
				email_tracking: action.payload.email_tracking || [],
				call_tracking: action.payload.call_tracking || [],
				costs: action.payload.costs || [],
				cid_generator: action.payload.cid_generator,
				ga_counter: action.payload.ga_counter || '',
				track_id: action.payload.track_id,
				notifications: action.payload.notifications,
				data_lens_settings: action.payload.data_lens_settings || initialState['data_lens_settings'],
				fetching: false,
			};
		case SETTINGS_GET_ERROR:
			return {
				...state,
				fetching: false,
			};

		case SETTINGS_UPDATE_START:
			return {
				...state,
				fetching: true,
			};
		case SETTINGS_UPDATE_SUCCESS:
			return {
				...state,
				fetching: false,
			};
		case SETTINGS_UPDATE_ERROR:
			return {
				...state,
				fetching: false,
			};

		default:
			return state;
	}
};
