export const DATALENS_CHECK_DB_START = 'DATALENS_CHECK_DB_START';
export const DATALENS_CHECK_DB_SUCCESS = 'DATALENS_CHECK_DB_SUCCESS';
export const DATALENS_CHECK_DB_ERROR = 'DATALENS_CHECK_DB_ERROR';

export const dataLensCheckDbStart = payload => {
	return {
		type: DATALENS_CHECK_DB_START,
		payload,
	};
};

export const dataLensCheckDbSuccess = payload => {
	return {
		type: DATALENS_CHECK_DB_SUCCESS,
		payload,
	};
};

export const dataLensCheckDbError = payload => {
	return {
		type: DATALENS_CHECK_DB_ERROR,
		payload,
	};
};
