import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';

/** Other components */
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';

/** Custom components */
import CircularProgressStyled from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */
import { yaCountersFetchStart } from 'actions/yaActions';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
}));

const YaForm = ({
	open,
	cost,
	isFetching,
	SettingsModel,
	loadingState,
	setLoadingState,
	setGaStepsCompetedState,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [activeStep, setActiveStep] = useState(0);
	const [stepEditState, setStepEditState] = useState(false);

	const [yaCounterIdState, setYaCounterIdState] = useState(false);
	const [yaCounterNameState, setYaCounterNameState] = useState('');

	const yaCounterIdSettings = useMemo(() => cost.accountId || null);

	useEffect(() => {
		if ('accountId' in cost && cost.accountId)
			setYaCounterIdState(cost.accountId);

		if ('accountName' in cost && cost.accountName)
			setYaCounterNameState(cost.accountName);
	}, [cost]);

	const fetchYaCounters = () => dispatch(yaCountersFetchStart({}));

	useEffect(() => {
		fetchYaCounters();
	}, []);

	const yaCountersData = useSelector(state => state.ya_counters);

	const yaCounterIdSave = () => {
		const {
			name: accountName = '',
		} = yaCountersData.items?.data?.counters.find(
			item => item.id === yaCounterIdState,
		);

		SettingsModel.costs = {
			id: cost.id,
			accountId: yaCounterIdState,
			accountName,
		};

		/** Reset next step values */

		setLoadingState({
			...loadingState,
			costs: { [cost.id]: true },
		});

		SettingsModel.saveSettings();
		setStepEditState(1);
	};

	const backToAccount = () => {
		setStepEditState(0);
	};

	const yaCounterIdEdit = useCallback(event => {
		setYaCounterIdState(event.target.value);
	});

	useEffect(() => {
		const isSomeFetching = !!isFetching;

		if (yaCounterIdSettings && stepEditState === 1 && !isSomeFetching) {
			setActiveStep(1);
		}

		if (yaCounterIdSettings && !stepEditState && !isSomeFetching) {
			setActiveStep(1);
		}

		if (yaCounterIdSettings && stepEditState === 0 && !isSomeFetching) {
			setActiveStep(0);
		}

		if (
			(activeStep === 1 && yaCounterIdSettings && stepEditState === 1) ||
			(yaCounterIdSettings && !stepEditState && !isSomeFetching)
		)
			setGaStepsCompetedState(true);

		if (stepEditState === 0) setActiveStep(0);
	});

	console.log('yaCounterIdSettings', yaCounterIdSettings);
	console.log('stepEditState', stepEditState);
	console.log('activeStep', activeStep);

	const yaCounterValue = useMemo(() => {
		return yaCounterIdState !== false
			? yaCounterIdState
			: yaCounterIdSettings
				? yaCounterIdSettings
				: 'default';
	}, [yaCounterIdState, yaCounterIdSettings]);

	return (
		<Stepper activeStep={activeStep} orientation="vertical">
			<Step key={0}>
				<StepLabel>
					{yaCounterIdSettings &&
						yaCountersData?.items?.data?.counters.some(
							account => account.id == yaCounterIdSettings,
						) ? (
						<StepLabel>
							{yaCounterNameState}
							{yaCounterIdSettings && activeStep > 0 ? (
								<Button onClick={backToAccount}>
									<EditIcon fontSize="small" />
								</Button>
							) : null}
						</StepLabel>
					) : (
						<StepLabel>{t('Select YA account')}</StepLabel>
					)}
				</StepLabel>
				<StepContent>
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-age-native-simple">
							{'account'}
						</InputLabel>
						<Select
							label={'account'}
							onChange={yaCounterIdEdit}
							value={yaCounterValue}
							style={{ width: 450 }}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value="default">-</MenuItem>
							{yaCountersData.items?.data?.counters.map((account, i) => (
								<MenuItem value={account.id} key={i}>
									{account.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								variant="contained"
								color="primary"
								onClick={yaCounterIdSave}
								className={classes.button}
								disabled={
									!yaCounterIdState ||
									yaCounterIdState == yaCounterIdSettings ||
									yaCounterIdState == 'default' ||
									isFetching
								}
							>
								{t('Save')}
								<SaveIcon />
								{isFetching ? <CircularProgressStyled size={24} /> : null}
							</Button>
						</div>
					</div>
				</StepContent>
			</Step>
		</Stepper>
	);
};

export default YaForm;
