import { call, put, take } from 'redux-saga/effects';

import { getAdsAccounts, getCounters, getGoals } from 'api/ya';

import {
	yaAdsAccountsFetchSuccess,
	yaAdsAccountsFetchError,
	YA_ADS_ACCOUNTS_FETCH_START,
	yaCountersFetchSuccess,
	yaCountersFetchError,
	YA_COUNTERS_FETCH_START,
	yaGoalsFetchSuccess,
	yaGoalsFetchError,
	YA_GOALS_FETCH_START,
} from 'actions/yaActions';

export function* yaAdsAccountsFlow() {
	while (true) {
		yield take(YA_ADS_ACCOUNTS_FETCH_START);

		try {
			const data = yield call(getAdsAccounts);
			yield put(yaAdsAccountsFetchSuccess(data));
		} catch (error) {
			yield put(yaAdsAccountsFetchError(error));
		}
	}
}

export function* yaCountersFlow() {
	while (true) {
		yield take(YA_COUNTERS_FETCH_START);

		try {
			const data = yield call(getCounters);
			yield put(yaCountersFetchSuccess(data));
		} catch (error) {
			yield put(yaCountersFetchError(error));
		}
	}
}

export function* yaGoalsFlow() {
	while (true) {
		const action = yield take(YA_GOALS_FETCH_START);

		try {
			const data = yield call(getGoals, action.payload);
			yield put(yaGoalsFetchSuccess(data));
		} catch (error) {
			yield put(yaGoalsFetchError(error));
		}
	}
}
