import React, { useState, useCallback } from 'react';

import parse from 'html-react-parser';

/** Icons  */

import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

import SettingsIcon from '@material-ui/icons/Settings';

/** Other components */
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TableRow from '@material-ui/core/TableRow';

import Skeleton from '@material-ui/lab/Skeleton';

/** Custom components */
import TableCellStyled from 'components/common/TableCellStyled';

/** Sub components */
import VkCostsSettings from './Vkontakte/CostsSettings';
import FacebookCostsSettings from './Facebook/CostsSettings';
import YandexCostsSettings from './Yandex/CostsSettings';
import VkAuth from 'components/auth/VkAuth';
import FbAuth from 'components/auth/FbAuth';
import YaAuth from 'components/auth/YaAuth';

/** Actions */
import { authCheckVkFetchStart } from 'actions/authActions';
import { authCheckFbFetchStart } from 'actions/authActions';
import { authCheckYaFetchStart } from 'actions/authActions';

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	avatarSmall: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	rowDone: { backgroundColor: 'rgba(144, 255, 100, 0.2)' },
	rowWarning: { backgroundColor: 'rgba(255, 229, 100, 0.2)' },
}));

const CostItem = ({
	cost,
	isFetching,
	costDelete,
	SettingsModel,
	loadingState,
	setLoadingState,
}) => {
	const classes = useStyles();

	const [editFormOpenState, setEditFormOpenState] = useState(false);

	let sourceLogo = null;
	let EditForm = null;
	let AuthForm = null;
	let linkToSource = null;
	let sourceDataLabel = null;
	let authCheckAction = null;
	let authStateKey = null;

	switch (cost.source) {
		case CONFIG.COST_SOURCE_FACEBOOK:
			sourceLogo = (
				<span style={{ whiteSpace: 'normal' }}>
					<Avatar src="/resources/FbLogo.png" className={classes.avatarSmall} />
					<Avatar
						src="/resources/InstLogo.jpg"
						className={classes.avatarSmall}
					/>
				</span>
			);
			EditForm = FacebookCostsSettings;
			sourceDataLabel =
				'accountName' in cost.sourceData && cost.sourceData.accountName
					? `${cost.sourceData.accountName} (${cost.sourceData.accountId})`
					: null;
			linkToSource =
				'accountName' in cost.sourceData && cost.sourceData.accountName
					? parse(
						`<a target="_blank" href="https://www.facebook.com/adsmanager/manage/campaigns?act=${cost.sourceData.accountName}">${sourceDataLabel}</a>`,
					)
					: null;
			authCheckAction = authCheckFbFetchStart;
			authStateKey = 'fb';
			AuthForm = FbAuth;

			break;
		case CONFIG.COST_SOURCE_VKONTAKTE:
			sourceLogo = (
				<span style={{ whiteSpace: 'normal' }}>
					<Avatar src="/resources/VkLogo.png" className={classes.avatarSmall} />
				</span>
			);
			EditForm = VkCostsSettings;
			sourceDataLabel =
				'accountName' in cost.sourceData && cost.sourceData.accountName
					? `${cost.sourceData.accountName} (${cost.sourceData.accountId})`
					: null;
			linkToSource =
				'accountName' in cost.sourceData && cost.sourceData.accountName
					? parse(
						`<a target="_blank" href="https://vk.com/ads?act=office&union_id=${cost.sourceData.accountId}">${sourceDataLabel}</a>`,
					)
					: null;
			authCheckAction = authCheckVkFetchStart;
			authStateKey = 'vk';
			AuthForm = VkAuth;

			break;
		case CONFIG.COST_SOURCE_YANDEX:
			sourceLogo = (
				<span style={{ whiteSpace: 'normal' }}>
					<Avatar src="/resources/YdLogo.png" className={classes.avatarSmall} />
				</span>
			);
			EditForm = YandexCostsSettings;
			sourceDataLabel =
				'accountName' in cost.sourceData && cost.sourceData.accountName
					? `${cost.sourceData.accountName} `
					: null;
			linkToSource =
				'accountName' in cost.sourceData && cost.sourceData.accountName
					? `${sourceDataLabel}`
					: null;
			authCheckAction = authCheckYaFetchStart;
			authStateKey = 'ya';
			AuthForm = YaAuth;

			break;
		default:
			sourceLogo = null;
			break;
	}

	const toggleEditForm = useCallback(() => {
		setEditFormOpenState(!editFormOpenState);
	});

	const costSettingsDone =
		cost.sourceData.accountId && cost.sourceData.accountName && cost.accountId ? true : false;

	const emptyDataIcon = '⚠️';
	const wellDoneIcon = '✅';
	const arrowIcon = '➡️';

	const linkToDataSetLog =
		cost.accountId &&
			cost.internalWebPropertyId &&
			cost.profileId &&
			cost.customDataSourceId
			? parse(
				`<a target="_blank" href="https://analytics.google.com/analytics/web/#/a${cost.accountId}w${cost.internalWebPropertyId}p${cost.profileId}/admin/dataimport/datasets/${cost.customDataSourceId}/records">LOGS</a > `,
			)
			: null;

	return (
		<TableRow
			className={costSettingsDone ? classes.rowDone : classes.rowWarning}
		>
			<TableCellStyled>
				{isFetching ? (
					<Skeleton animation={'wave'} />
				) : (
					<Button
						color={'default'}
						size={'small'}
						disabled={false}
						onClick={toggleEditForm}
					>
						<SettingsIcon fontSize={'small'} />
					</Button>
				)}
			</TableCellStyled>
			<TableCellStyled align="right">
				{isFetching ? (
					<Skeleton animation={'wave'} />
				) : linkToSource ? (
					linkToSource
				) : (
					emptyDataIcon
				)}
			</TableCellStyled>
			<TableCellStyled align="right"></TableCellStyled>
			<TableCellStyled align="right">
				{isFetching ? <Skeleton animation={'wave'} /> : sourceLogo}
			</TableCellStyled>
			<TableCellStyled align="left">
				{isFetching ? <Skeleton animation={'wave'} /> : arrowIcon}
			</TableCellStyled>
			<TableCellStyled>
				{isFetching ? (
					<Skeleton animation={'wave'} />
				) : (
					<span style={{ whiteSpace: 'normal' }}>
						<Avatar src="/resources/YaLogo.png" />
					</span>
				)}
			</TableCellStyled>
			<TableCellStyled align="right">
				{isFetching ? (
					<Skeleton animation={'wave'} />
				) : cost.accountName ? (
					<>
						{wellDoneIcon}
						<br />
						{cost.accountName}
					</>
				) : (
					emptyDataIcon
				)}
			</TableCellStyled>
			<TableCellStyled align="right">
				{isFetching ? (
					<Skeleton animation={'wave'} />
				) : linkToDataSetLog ? (
					linkToDataSetLog
				) : (
					''
				)}
			</TableCellStyled>
			<TableCellStyled align="right">
				{isFetching ? (
					<Skeleton animation={'wave'} />
				) : (
					<Button disabled={false}>
						<DeleteSweepIcon fontSize={'small'} onClick={costDelete} />
					</Button>
				)}
			</TableCellStyled>
			<EditForm
				open={editFormOpenState}
				toggleEditForm={toggleEditForm}
				cost={cost}
				isFetching={isFetching}
				SettingsModel={SettingsModel}
				loadingState={loadingState}
				setLoadingState={setLoadingState}
				authCheckAction={authCheckAction}
				authStateKey={authStateKey}
				AuthForm={AuthForm}
			/>
		</TableRow>
	);
};

export default CostItem;
