const en = {
	translation: {
		/** Settings and descriptions */

		'Select the direction (funnel) of deals for setting Google Analytics goals':
			'Select the direction (funnel) of deals for setting Google Analytics goals. 📹  <a target="_blank" href="https://vimeo.com/503190383">example</a>',

		'Client Id field description': `The field in which you will store <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters?hl=en#cid">Client ID Google Analytics!</a> ⚡ Should be filled! ⚡`,
		'User Id function description': `About the User-ID feature <a target="_blank" href="https://support.google.com/analytics/answer/3123662?hl=en">in detail</a>`,

		'Tid field description':
			'Please note that if a field is selected in this parameter, the counter code settings from the general settings and settings for each direction will be ignored. Leave it empty if you don`t have a clue what it is about 🤪',

		'Custom dimension field desctiption': `To pass custom properties to the measurement protocol, set the correspondence to a custom parameter in Google Analytics
		<a href="https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters?hl=en#cd_" target=_blank>
		more details
		</a>
		`,

		/** Category */
		'Category description': `Specify Google Analytics account, counter and view, 
		which goals need to be linked to the stage of the transaction on the left. And just drag the desired target to the desired stage on the right side.`,

		'Category GA counter substitution warning': `Note! The field for storing the counter code is specified in the general settings: <b><u>{{ gaCounterUserFieldName }}</u></b>. 
	If it will have a value in the CRM card, then the current value <b><u>{{ gaCounterInUse }}</u></b>
	will be ignored`,

		'Expert mode description': `When the mode is activated, the parameters of targets for stages
		can be specified manually. <b>ATTENTION!</b> If you have incorrectly specified parameters of a goal the target will not be
		get registered in Google Analytics.
		Select targets directly from the list on the left
		parts`,

		/** GeneralSettings */
		'Js code for placing on your website':
			'Js code for placing on your website',

		'Js code description': `Place this code on all pages of your site, then
		Google Client Id required for accurate data analysis will be
		automatically added to the result of CRM forms. Also, cid will be
		added to leads/deals created from all widget channels
		(Bitrix24 chat, Whatsapp, Telegram, etc.).  This code required for
		<a href="https://b242ga.com/blog/track_id" target="_blank">
			track id
		</a>`,

		'Auth description': `The list of external auth`,

		'GA counter by default description': `For support multiple websites, the counter code can
		be passed from different fields, depending on which of
		filled with them. <br /> <br />
		The code is transmitted if: <br />
		1) There is a value in the field "Tid field" of the deal or lead
		<br />
		2) There is a value in the field "counter code" in the direction settings
		and the field from clause 1 did not fill<br />
		<br />
		If the fields in items 1 and 2 are empty, then the value from
		the field below will be sent.`,
		'Track id title': 'Manage <b> TRACK ID </b> in the lead and deal crm card',
		'Track id description settings': `When the option is enabled, a tab will appear in the lead and deal card
		B242GA. 🙄 But
		<a target="_blank" href="https://b242ga.com/blog/track_id">
			what is it?
		</a>`,

		/** Costs*/
		'Costs settings title': 'Add advertising source',
		'Costs settings description':
			'Click on the desired source, it will be added to the list below for customization. 📹 <a target="_blank" href="https://vimeo.com/504562660">example</a>',

		/** CostSettings Form */
		'Create Data Set': ` <a href="{{ link }}" target=_blank>Create Data Set</a><br /><br />
		Please note that you should create a Scheme with the next fields:<br />
		<ul><li>ga:date</li><li>ga:medium</li><li>ga:source</li><li>ga:adClicks</li><li>ga:impressions</li><li>ga:adCost</li><li>ga:campaign</li><li>ga:keyword</li><li>ga:adContent</li></ul>
		`,
		'Create Data Set Example': ` <a href="{{ link }}" target=_blank>Create Data Set Example</a>`,
		'Data set required fileds': `Please be sure that your Scheme contains all the next fileds:<br />
		<ul><li>ga:date</li><li>ga:medium</li><li>ga:source</li><li>ga:adClicks</li><li>ga:impressions</li><li>ga:adCost</li><li>ga:campaign</li><li>ga:keyword</li><li>ga:adContent</li></ul>`,

		'Mapping Google Analytics API Fields':
			'Mapping Google Analytics Schema ⬅️ <a target="_blank" href="{{link}}">{{source}}</a>',

		'There is no Data set': `There is no Data Set for the specified counter. <br />
		Please create a Data Set (links below) <br /> and update the data for this setup step.
		`,

		'Data set has no view link': `The specified Data Set is not bound to any View, <br /> please do this, it is mandatory`,
		'Set utm tags': `Be sure to include the following fields in the Data Set schema <br /> <b> ga: date ga: medium ga: source ga: adClicks ga: adCost ga: impressions ga: campaign </b>`,

		/** Auths */
		'The Google Analytics authorization will be removed': `The Google Analytics authorization will be removed. All application settings, all upload streams !!`,
		'The Facebook authorization will be removed': `The Facebook authorization will be removed. All upload streams related to Facebook will be deleted!`,
		'The Vk authorization will be removed': `The Vk authorization will be removed. When you push the "Yes" button, all upload streams related to Vk will be deleted! Vk API does not support revoke access method, so you should delete it manually from the list of applications, please follow this link <a href="https://vk.com/settings?act=apps" target="_blank">delete auth from vk</a>`,
		'The Yandex authorization will be removed': `The Yandex authorization will be removed. When you push the "Yes" button, all upload streams related to Yandex will be deleted! Yandex API does not support revoke access method, so you should delete it manually from the list of applications, please follow this link <a href="https://passport.yandex.ru/profile" target="_blank">delete auth from yandex</a>`,

		/** Generator */
		'Generator description': `More about why 
		<a href="https://b242ga.com/blog/cid_generation" target="_blank">
		this
		</a>
		Also can be organized with this function 
		<a href="https://b242ga.ru/blog/email_tracking" target="_blank">
		static email tracking
		</a> 
		to track GA hits by email`,

		'Generator caution': `
				What will happen when you turn it on:
				<br />
				<br />
				1.If the lead / deal does not have a CID defined, it will be generated and saved in CRM
				<br /><br />
				2. If the lead / deal does not have UTM_SOURCE defined, but there is a source from the Bitrix24 directory, then utm tags will be set from the list on the right
				<br /><br />
				3. If the lead / deal does not have a source or if no labels are set for the source in the list, labels from the source will be automatically set <b>B242GA_NO SOURCE</b> (see the last item in the list).`,

		/** CrmDetailTabs */
		'Track id description': `Ask the caller to press Esc and dictate the number to you. Enter
		its in the Track id field. How it works
				<a href="https://b242ga.com/blog/track_id" target="_blank">
					in detail
				</a>`,

		/** Email tracking */
		'Add new email': 'New email:',
		'List of emails': 'Emails list:',
		'Email tracking description': `How does it work? <br />
		<p> ✔️ Place the script b242ga.js on your website page.</p>
		<p> ✔️ Fill a pair html class name / email on the right side. For example <b>email-1</b> / <b>order+#ID#@b242ga.com</b></p>
		<p> ✔️ Place HTML-tag on your website with an attribute class="email-1". For example span class="email-1"</p>
		<p> ✔️ Do 🔗<a href="https://helpdesk.bitrix24.com/open/8236675/" target=_blank>Mailbox integration</a> for your mailbox. For example order@b242ga.com</p>
		<p> ✔️ A lead or a deal, which will be created by a letter to the configured address, will contain the CID and UTM tags that the client used to visit the site - B242GA will take 🧚🧚care🧚🧚 about it.</p> `,

		'Class name warning epmty': 'should contain a class name',
		'Email track empty': 'enter email ​',
		'Email track need ID':
			'#ID# is requires, for example order+#ID#@b242ga.com',

		/** Call tracking */
		'Add new phone': 'New phone:',
		'List of phones': 'List of phone numbers:',
		'Call tracking description': `<b>How to set up dynamic call tracking</b>. <br />
							<p> ✔️ Place the b242ga.js script from the settings section on the site page</p>
							<p> ✔️ 🔗<a href="https://helpdesk.bitrix24.com/open/8812825/" target=_blank>Connect a package of phone numbers to your portal</a>.
							The more numbers in the package, the lower the error in determining the tracking data.
							<p> ✔️ In the block on the right, add a list of phone numbers from the package. <br />
							<p> ✔️ Add
							b242ga-call-tracker. For example, if the phone on the page is placed in the div class = "phone" tag, then for dynamic substitution add to the class
							 div class="phone <b>b242ga-call-tracker</b>".
							<p> ✔️ A lead or a deal, which will be created by calling a number from the list on the right, will contain the CID and UTM tags - 🧚🧚<b>B242GA</b>🧚🧚 will take care about that </p> `,
		'Phone track empty': 'enter a phone number ​',
		'Phone track error': 'phone number error​',
		'Phone track pool name empty': 'enter a pool name value',
		'Phone track pool name error': 'the valid values include 0-9, Latin characters, and -',

		/** Menu items */
		'M.DEALS': 'DEALS',
		'M.DEALS': 'DEALS',
		'M.LEADS': 'LEADS',
		'M.SETTINGS': 'SETTINGS',
		'M.GENERATOR': 'SOURCES',
		'M.LOGS': 'LOGS',
		'M.COSTS': 'COSTS FOR ROI',
		'M.EMAIL-T': 'Email Tracking',
		'M.CALL-T': 'Call Tracking',
		'M.DATALENS': 'DataLens (soon)',
		/** Controls */
	},
};

export default en;
