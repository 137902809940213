const CONFIG = {
	GA_GOAL_NAME: 'GA_GOAL_NAME',
	GA_GOAL_ID: 'GA_GOAL_ID',
	GA_CATEGORY: 'GA_CATEGORY',
	GA_ACTION: 'GA_ACTION',
	GA_LABEL: 'GA_LABEL',
	GA_COUNTER: 'GA_COUNTER',
	GA_ACCOUNT_ID: 'GA_ACCOUNT_ID',
	GA_ACCOUNT_WEB_PROPERTY_ID: 'GA_ACCOUNT_WEB_PROPERTY_ID',
	GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID: 'GA_ACCOUNT_INTERNAL_WEB_PROPERTY_ID',
	GA_ACCOUNT_PROFILE_ID: 'GA_ACCOUNT_PROFILE_ID',

	YA_COUNTER_ID: 'YA_COUNTER_ID',
	YA_COUNTER_NAME: 'YA_COUNTER_NAME',
	YA_GOAL_ID: 'YA_GOAL_ID',
	YA_GOAL_NAME: 'YA_GOAL_NAME',
	YA_GOAL_URL: 'YA_GOAL_URL',
	YA_E2E_STATUS: 'YA_E2E_STATUS',

	GOAL_DRAG_TYPE: 'goal_drag_type',
	SOURCE_UTM_SOURCE: 'SOURCE_UTM_SOURCE',
	SOURCE_UTM_MEDIUM: 'SOURCE_UTM_MEDIUM',
	SOURCE_UTM_CAMPAIGN: 'SOURCE_UTM_CAMPAIGN',
	NO_SOURCE_SOURCE: {
		NAME: 'B242YA_NO_SOURCE',
		SORT: 900,
		STATUS_ID: 'B242YA_NO_SOURCE',
	},
	LANG: window.localStorage.getItem('b242gaLang') || 'en',
	COST_SOURCE_FACEBOOK: 'COST_SOURCE_FACEBOOK',
	COST_SOURCE_VKONTAKTE: 'COST_SOURCE_VKONTAKTE',
	COST_SOURCE_YANDEX: 'COST_SOURCE_YANDEX',
};

export default CONFIG;
