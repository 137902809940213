export const handleFbAuthWindow = successHandler => {
	let b24Auth = window.BX24.getAuth();

	window.authSuccess = function () {
		successHandler();
	};

	window.localStorage.setItem('memeberId', '');
	if (b24Auth.member_id)
		window.localStorage.setItem('memeberId', b24Auth.member_id);

	window.localStorage.setItem('fbAppId', process.env.REACT_APP_FB_APP_ID);

	window.localStorage.setItem(
		'fbRedirectUri',
		process.env.REACT_APP_FB_REDIRECT_URI,
	);

	let windowFeatures =
		'menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=340,height=480';
	window.open(`/fb.html`, 'FacebookAuth', windowFeatures);
};

export const handleVkAuthWindow = successHandler => {
	let b24Auth = window.BX24.getAuth();

	window.authSuccess = function () {
		successHandler();
	};

	window.localStorage.setItem('memeberId', '');
	if (b24Auth.member_id)
		window.localStorage.setItem('memeberId', b24Auth.member_id);

	window.localStorage.setItem('vkAppId', process.env.REACT_APP_VK_APP_ID);

	window.localStorage.setItem(
		'vkRedirectUri',
		process.env.REACT_APP_VK_REDIRECT_URI,
	);

	let windowFeatures =
		'menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=1500,height=780';
	window.open(`/vk.html`, 'VkAuth', windowFeatures);
};

export const handleYaAuthWindow = successHandler => {
	let b24Auth = window.BX24.getAuth();

	window.authSuccess = function () {
		successHandler();
	};

	window.localStorage.setItem('memeberId', '');
	if (b24Auth.member_id)
		window.localStorage.setItem('memeberId', b24Auth.member_id);

	window.localStorage.setItem('yaAppId', process.env.REACT_APP_YA_APP_ID);

	window.localStorage.setItem(
		'yaRedirectUri',
		process.env.REACT_APP_YA_REDIRECT_URI,
	);

	let windowFeatures =
		'menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=500,height=800';
	window.open(
		`/ya.html?memeberId=${b24Auth.member_id}&yaAppId=${process.env.REACT_APP_YA_APP_ID}&yaRedirectUri=${process.env.REACT_APP_YA_REDIRECT_URI}`,
		'YaAuth',
		windowFeatures,
	);
};
