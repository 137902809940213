import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';

/** Actions */
import { authCheckYaFetchStart } from 'actions/authActions';

/** Helpers */
import { handleYaAuthWindow } from 'helpers/auth';

const VkAuth = () => {
	const dispatch = useDispatch();

	const [showReloadState, setShowReloadState] = useState(false);

	const fetchAuthCheck = () => dispatch(authCheckYaFetchStart({}));

	window.authSuccess = function () {
		fetchAuthCheck();
	};

	const openYaAuth = useCallback(() => {
		handleYaAuthWindow(fetchAuthCheck);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setShowReloadState(true);
		}, '7000');
	}, []);

	return (
		<Grid
			container
			spacing={0}
			alignItems="center"
			justify="center"
			style={{ verticalAlign: '50%' }}
		>
			<Grid item xs={3} style={{ textAlign: 'center' }}>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<Typography variant="h5">Авторизация</Typography>
				<br />
				<Button onClick={openYaAuth} variant={'outlined'}>
					<img src="/resources/SignInWithYaMetrics.jpg" width="300" />
				</Button>
				<br />
				<br />
				{!showReloadState ? null : (
					<Button
						onClick={() => window.location.reload()}
						color={'primary'}
						variant={'contained'}
						size={'large'}
					>
						<CachedIcon />
						Перезагрузить B242YA
					</Button>
				)}
			</Grid>
		</Grid>
	);
};

export default VkAuth;
