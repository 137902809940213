import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import InfoSharpIcon from '@material-ui/icons/InfoSharp';

/** Other components */
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

/** Custom component */
import SaveButton from 'components/common/SaveButton';

/** Helpers */
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	option: {
		margin: 10,
		fontSize: '16px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		cursor: 'pointer',
	},
});

const GaCid = ({
	SettingsModel,
	settingKey,
	label,
	description,
	userFiledsList,
	isFetching,
	loadingState,
	setLoadingState,
	userFieldsListInUse,
}) => {
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const [gaCidState, setGaCidState] = useState('');

	const gaCidEdit = useCallback(event => {
		setGaCidState(event.target.value);
	});

	const gaCidSave = useCallback(() => {
		SettingsModel[settingKey] = gaCidState;
		setLoadingState({
			...loadingState,
			[settingKey]: true,
		});

		SettingsModel.saveSettings();
	}, [SettingsModel, gaCidState, loadingState]);

	const gaCidValue = gaCidState ? gaCidState : SettingsModel[settingKey];

	const selectError = gaCidValue == 'default' || !gaCidValue;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h5" component="h5">
					{label}
				</Typography>
				<div className="blockquote-info">
					<p>ℹ️ {description}</p>
				</div>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined">
					{selectError ? (
						<InputLabel className={'alarm'}>⚡{t('Required!')}⚡</InputLabel>
					) : (
						<InputLabel>{label}</InputLabel>
					)}
					<Select
						native
						value={gaCidValue}
						onChange={gaCidEdit}
						label={label}
						style={{ width: 500 }}
						inputProps={{
							id: 'name-native-error',
						}}
					>
						<option value={'default'} className={classes.option}>
							-
						</option>
						{userFiledsList.items.map((field, i) => (
							<option
								value={field.ID}
								key={i}
								className={classes.option}
								disabled={
									userFieldsListInUse.includes(field.ID) &&
									field.ID != gaCidValue
								}
							>
								{field.EDIT_FORM_LABEL[CONFIG.LANG.toLocaleLowerCase()]}
							</option>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<SaveButton
					handler={gaCidSave}
					disabled={gaCidValue == SettingsModel[settingKey] || selectError}
					isFetching={isFetching(settingKey)}
					label={'Сохранить'}
				/>
			</Grid>
		</Grid>
	);
};

export default GaCid;
