import React, { useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';

const useStyles = makeStyles({
	container: {
		height: '50%',
	},
});

export const AlertDialog = ({
	open,
	title,
	description,
	agreeHandler,
	closeHandler,
	showOkButton = true,
	showCancelButton = true,
}) => {
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const agree = useCallback(() => {
		agreeHandler();
		closeHandler();
	}, [agreeHandler, closeHandler]);

	const close = useCallback(() => {
		closeHandler();
	}, [closeHandler]);

	return (
		<Dialog
			open={open}
			onClose={close}
			classes={{
				container: classes.container,
			}}
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{description}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{!showCancelButton ? null : (
					<Button onClick={close} color="primary">
						{t('Cancel')}
					</Button>
				)}
				{!showOkButton ? null : (
					<Button onClick={agree} color="secondary">
						{'OK'}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
