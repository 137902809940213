import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';

/** Other components */
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

/** Icons */
import Badge from '@material-ui/core/Badge';

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles, styled } from '@material-ui/core/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: 5,
		top: 13,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
	},
}));

const useStyles = makeStyles({
	box: props => ({
		opacity: props.isDragging ? 0.4 : 1,
	}),
	enabledGoal: { cursor: 'grab', margin: 5 },
	disabledGoal: { margin: 5 },
});

const YaGoal = ({ SettingsModel, goal, stageSave }) => {
	const goalId = goal.id;
	const goalName = goal.name;
	const goalUrl = goal.conditions?.[0].url;

	const isDisabled = stage => {
		return CONFIG.YA_GOAL_ID in stage && stage[CONFIG.YA_GOAL_ID] === goalId;
	};

	let countOfGoalUsage = SettingsModel['dealsStages'].filter(
		stage => stage[CONFIG.YA_GOAL_ID] === goalId,
	).length;

	if (!countOfGoalUsage) {
		countOfGoalUsage = SettingsModel['leadsStages'].filter(
			stage => stage[CONFIG.YA_GOAL_ID] === goalId,
		).length;
	}

	//let disabled = SettingsModel['dealsStages'].some(isDisabled);
	//if (!disabled) disabled = SettingsModel['leadsStages'].some(isDisabled);
	const disabled = false;
	const item = { goal, type: CONFIG.GOAL_DRAG_TYPE };

	const [{ isDragging }, drag] = useDrag({
		item,
		end(item, monitor) {
			const dropResult = monitor.getDropResult();

			if (
				goalId &&
				dropResult &&
				'stage' in dropResult &&
				'STATUS_ID' in dropResult.stage &&
				dropResult.stage.STATUS_ID
			) {
				stageSave({
					STATUS_ID: dropResult.stage.STATUS_ID,
					IS_CONVERSION: dropResult.stage.IS_CONVERSION,
					yaGoalId: goalId,
					yaGoalName: goalName,
					yaGoalUrl: goalUrl,
					e2eStatus: dropResult.stage.YA_E2E_STATUS
						? dropResult.stage.YA_E2E_STATUS
						: dropResult.stage.IS_CONVERSION === 'Y'
							? 'PAID'
							: 'IN_PROGRESS',
				});
			}
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const classes = useStyles({ isDragging });

	return (
		<>
			{goalId ? (
				<span ref={disabled ? null : drag}>
					<StyledBadge badgeContent={countOfGoalUsage} color="secondary">
						<Chip
							avatar={<Avatar src="/resources/YaMetricLogo.png" />}
							label={`${goal.name}`}
							clickable
							color="primary"
							disabled={disabled}
							className={classes.enabledGoal}
						/>
					</StyledBadge>
				</span>
			) : (
				<Tooltip placement={'top'} title="У этой цели нет меток">
					<Chip
						avatar={<Avatar>{goal.name.slice(0, 1)}</Avatar>}
						label={`${goal.name}`}
						disabled={disabled}
						className={classes.disabledGoal}
					/>
				</Tooltip>
			)}
		</>
	);
};

export default YaGoal;
