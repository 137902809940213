import React, { useEffect, useState, memo } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	goalPreview: {
		display: 'inline-block',
		transform: 'rotate(-7deg)',
		WebkitTransform: 'rotate(-7deg)',
	},
});

const GoalDragPreview = memo(({ title }) => {
	const classes = useStyles();

	return (
		<div className={classes.goalPreview}>
			<Chip
				avatar={<Avatar>{title.slice(0, 1)}</Avatar>}
				label={`${title}`}
				clickable
				color="primary"
				variant="outlined"
			/>
		</div>
	);
});

export default GoalDragPreview;
