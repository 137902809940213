import { call, put, take } from 'redux-saga/effects';

import { checkConnectionDb } from 'api/dataLensConnection';

import {
	dataLensCheckDbSuccess,
	dataLensCheckDbError,
	DATALENS_CHECK_DB_START,
} from 'actions/dataLensConnectionAction';

export function* dataLensConnectionFlow() {
	while (true) {
		const { payload } = yield take(DATALENS_CHECK_DB_START);

		try {
			const result = yield call(checkConnectionDb, payload);

			yield put(dataLensCheckDbSuccess(result.data));
		} catch (error) {
			yield put(dataLensCheckDbError(error));
		}
	}
}
