export const YA_ADS_ACCOUNTS_FETCH_START = 'YA_ADS_ACCOUNTS_FETCH_START';
export const YA_ADS_ACCOUNTS_FETCH_SUCCESS = 'YA_ADS_ACCOUNTS_FETCH_SUCCESS';
export const YA_ADS_ACCOUNTS_FETCH_ERROR = 'YA_ADS_ACCOUNTS_FETCH_ERROR';

export const yaAdsAccountsFetchStart = payload => {
	return {
		type: YA_ADS_ACCOUNTS_FETCH_START,
		payload,
	};
};

export const yaAdsAccountsFetchSuccess = payload => {
	return {
		type: YA_ADS_ACCOUNTS_FETCH_SUCCESS,
		payload,
	};
};

export const yaAdsAccountsFetchError = payload => {
	return {
		type: YA_ADS_ACCOUNTS_FETCH_ERROR,
		payload,
	};
};

export const YA_COUNTERS_FETCH_START = 'YA_COUNTERS_FETCH_START';
export const YA_COUNTERS_FETCH_SUCCESS = 'YA_COUNTERS_FETCH_SUCCESS';
export const YA_COUNTERS_FETCH_ERROR = 'YA_COUNTERS_FETCH_ERROR';

export const yaCountersFetchStart = payload => {
	return {
		type: YA_COUNTERS_FETCH_START,
		payload,
	};
};

export const yaCountersFetchSuccess = payload => {
	return {
		type: YA_COUNTERS_FETCH_SUCCESS,
		payload,
	};
};

export const yaCountersFetchError = payload => {
	return {
		type: YA_COUNTERS_FETCH_ERROR,
		payload,
	};
};

export const YA_GOALS_FETCH_START = 'YA_GOALS_FETCH_START';
export const YA_GOALS_FETCH_SUCCESS = 'YA_GOALS_FETCH_SUCCESS';
export const YA_GOALS_FETCH_ERROR = 'YA_GOALS_FETCH_ERROR';
export const YA_GOALS_RESET = 'YA_GOALS_RESET';

export const yaGoalsFetchStart = payload => {
	return {
		type: YA_GOALS_FETCH_START,
		payload,
	};
};

export const yaGoalsFetchSuccess = payload => {
	return {
		type: YA_GOALS_FETCH_SUCCESS,
		payload,
	};
};

export const yaGoalsFetchError = payload => {
	return {
		type: YA_GOALS_FETCH_ERROR,
		payload,
	};
};

export const yaGoalsReset = () => {
	return {
		type: YA_GOALS_RESET,
	};
};
