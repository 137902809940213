import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Tables  */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

/** Other components */
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';

/** Custom components */
import SaveButton from 'components/common/SaveButton';
import DrawerStyled from 'components/common/DrawerStyled';
import Chip from 'components/common/ChipStyled';

/** Sub components */
import GaProfile from './GaProfile';
import YaProfile from './YaProfile';
import StageItem from './StageItem';

/** Actions */

/** Helpers */
import CONFIG from 'helpers/const';
import { fitWindow } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	tableContainerStages: {
		maxHeight: 1000,
	},
	tableContainerGoals: {
		maxHeight: 700,
	},
	table: {
		maxWidth: 1050,
	},
	tdStage: {
		maxWidth: 350,
		height: 80,
	},
	td: {
		maxWidth: 120,
		height: 80,
	},
	tdExpertMode: {
		verticalAlign: 'top',
	},
	tdCounter: {
		minWidth: 200,
		height: 150,
		verticalAlign: 'top',
	},
	gridStage: {
		borderLeft: `2px solid`,
		borderLeftColor: '#e0e0e0',
		padding: '15px',
	},
	gridGoals: { padding: '15px' },
	categoryButton: {
		margin: 5,
	},
	categoryTitle: {
		marginLeft: 3,
		marginBottom: 30,
	},
	categoryTitleChain: {
		marginLeft: 27,
	},
	noWrap: {
		display: 'inline-block',
		whitSpace: 'nowrap',
	},
});

const Category = ({
	SettingsModel,
	categoryList,
	stageList,
	fetchCategoryStage,
	settingKeyCategories,
	settingKeyStages,
	settingsKeyCounter,
	userFiledsList,
	label,
	description,
	isFetching,
	loadingState,
	setLoadingState,
	lead,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const [categoryListState, setCategoryListState] = useState({});
	const [categoryLayerOpenIdState, setCategoryLayerOpenIdState] = useState(
		false,
	);
	const [categoryNameState, setCategoryName] = useState('');
	const [expertModeState, setExpertModeState] = useState(false);

	const expertModeToggle = () => {
		setExpertModeState(!expertModeState);
	};

	const categoryListFiltred = useMemo(() => {
		let categories = categoryList; // IMMER
		categories.items.forEach((category, i) => {
			categories.items[i] = {
				...categories.items[i],
				...SettingsModel[settingKeyCategories].find(
					setting => setting.ID == category.ID,
				),
			};
		});

		return categories;
	}, [SettingsModel]);

	const stageListFiltred = useMemo(() => {
		let stages = stageList;
		stages.items.forEach((stage, i) => {
			stages.items[i] = {
				...stages.items[i],
				...SettingsModel[settingKeyStages].find(
					setting => setting.STATUS_ID == stage.STATUS_ID,
				),
			};
		});

		return stages;
	}, [SettingsModel]);
	/*
	const stageListFiltred = useSelector(state => {
		state.deals_category_stage.items.forEach((stage, i) => {
			state.deals_category_stage.items[i] = {
				...state.deals_category_stage.items[i],
				...SettingsModel[settingKeyStages].find(
					setting => setting.STATUS_ID == stage.STATUS_ID
				)
			};
		});

		return state.deals_category_stage;
	});
*/
	const handleOpenCategory = useCallback(
		categoryId => {
			const categoryName = categoryListFiltred.items.find(
				category => category.ID === categoryId,
			)['NAME'];

			setCategoryLayerOpenIdState(categoryId);
			setCategoryName(categoryName);
			fetchCategoryStage({ id: categoryId });
		},
		[categoryLayerOpenIdState, categoryListFiltred],
	);

	const stageSave = ({
		STATUS_ID,
		IS_CONVERSION,
		category,
		action,
		label,
		goalId,
		goalName,
		yaGoalId,
		yaGoalName,
		yaGoalUrl,
		e2eStatus,
	}) => {
		SettingsModel[settingKeyStages] = {
			STATUS_ID,
			IS_CONVERSION,
			[CONFIG.GA_CATEGORY]: category,
			[CONFIG.GA_ACTION]: action,
			[CONFIG.GA_LABEL]: label,
			[CONFIG.GA_GOAL_ID]: goalId,
			[CONFIG.GA_GOAL_NAME]: goalName,
			[CONFIG.YA_GOAL_ID]: yaGoalId,
			[CONFIG.YA_GOAL_NAME]: yaGoalName,
			[CONFIG.YA_GOAL_URL]: yaGoalUrl,
			[CONFIG.YA_E2E_STATUS]: e2eStatus,
		};

		console.log('LINK GOAL', {
			STATUS_ID,
			IS_CONVERSION,
			[CONFIG.GA_CATEGORY]: category,
			[CONFIG.GA_ACTION]: action,
			[CONFIG.GA_LABEL]: label,
			[CONFIG.GA_GOAL_ID]: goalId,
			[CONFIG.GA_GOAL_NAME]: goalName,
			[CONFIG.YA_GOAL_ID]: yaGoalId,
			[CONFIG.YA_GOAL_NAME]: yaGoalName,
			[CONFIG.YA_GOAL_URL]: yaGoalUrl,
			[CONFIG.YA_E2E_STATUS]: e2eStatus,
		});

		setLoadingState({
			...loadingState,
			[settingKeyStages]: { [STATUS_ID]: true },
		});

		SettingsModel.saveSettings();
	};

	const stageReset = useCallback(STATUS_ID => {
		console.log(
			'RESET',
			STATUS_ID,
			settingKeyStages,
			SettingsModel[settingKeyStages],
		);

		SettingsModel[settingKeyStages] = {
			STATUS_ID: STATUS_ID,
			[CONFIG.GA_CATEGORY]: '',
			[CONFIG.GA_ACTION]: '',
			[CONFIG.GA_LABEL]: '',
			[CONFIG.GA_GOAL_ID]: '',
			[CONFIG.GA_GOAL_NAME]: '',
			[CONFIG.YA_GOAL_ID]: '',
			[CONFIG.YA_GOAL_NAME]: '',
			[CONFIG.YA_GOAL_URL]: '',
			//[CONFIG.YA_E2E_STATUS]: '',
		};

		console.log(
			'RESET AFTER',
			STATUS_ID,
			settingKeyStages,
			SettingsModel[settingKeyStages],
		);

		setLoadingState({
			...loadingState,
			[settingKeyStages]: { [STATUS_ID]: true },
		});

		SettingsModel.saveSettings();
	});

	const stagesReset = useCallback(params => {
		let loadStateStage = {};

		params.forEach(
			STATUS_ID =>
			(SettingsModel[settingKeyStages] = {
				STATUS_ID,
				[CONFIG.GA_CATEGORY]: '',
				[CONFIG.GA_ACTION]: '',
				[CONFIG.GA_LABEL]: '',
				[CONFIG.GA_GOAL_ID]: '',
				[CONFIG.GA_GOAL_NAME]: '',
				[CONFIG.YA_GOAL_NAME]: '',
				[CONFIG.YA_GOAL_ID]: '',
				[CONFIG.YA_GOAL_URL]: '',
				//[CONFIG.YA_E2E_STATUS]: '',
			}),
		);

		params.forEach(STATUS_ID => (loadStateStage[STATUS_ID] = true));

		setLoadingState({
			...loadingState,
			[settingKeyStages]: loadStateStage,
		});

		SettingsModel.saveSettings();
	});

	const disableCategoryEdit = useCallback(
		ID => {
			if (ID in categoryListState) {
				delete categoryListState[ID];

				setCategoryListState({
					...categoryListState,
				});
			}
		},
		[categoryListState],
	);

	const gaCounterEdit = useCallback(
		(ID, event) => {
			setCategoryListState({
				...categoryListState,
				...{
					[ID]: {
						...categoryListState[ID],
						[CONFIG.GA_COUNTER]: event.target.value,
					},
				},
			});
		},
		[categoryLayerOpenIdState, categoryListState],
	);

	const categorySave = ID => {
		SettingsModel[settingKeyCategories] = {
			ID,
			[CONFIG.GA_COUNTER]: categoryListState[ID][CONFIG.GA_COUNTER],
		};

		setLoadingState({
			...loadingState,
			[settingKeyCategories]: { [ID]: true },
		});

		SettingsModel.saveSettings();
		disableCategoryEdit(ID);
	};

	const gaCounterValue = (categoryLayerOpenIdState in categoryListState
		? categoryListState[categoryLayerOpenIdState][CONFIG.GA_COUNTER]
		: categoryListFiltred.items.some(
			category => category.ID == categoryLayerOpenIdState,
		) &&
			CONFIG.GA_COUNTER in
			categoryListFiltred.items.find(
				category => category.ID == categoryLayerOpenIdState,
			)
			? categoryListFiltred.items.find(
				category => category.ID == categoryLayerOpenIdState,
			)[CONFIG.GA_COUNTER]
			: ''
	).trim();

	let gaCounterCustom = '';
	let gaCounterFromGa = '';
	let gaCounterUserFieldName = '';

	if (
		Array.isArray(SettingsModel[settingKeyCategories]) &&
		SettingsModel[settingKeyCategories].length > 0
	) {
		const categoriesSettings =
			SettingsModel[settingKeyCategories].find(
				category => category.ID == categoryLayerOpenIdState,
			) || {};

		if ('GA_ACCOUNT_WEB_PROPERTY_ID' in categoriesSettings)
			gaCounterFromGa = categoriesSettings['GA_ACCOUNT_WEB_PROPERTY_ID'] || '';

		if (CONFIG.GA_COUNTER in categoriesSettings)
			gaCounterCustom = categoriesSettings[CONFIG.GA_COUNTER] || '';
	}

	if (SettingsModel[settingsKeyCounter] && 'items' in userFiledsList) {
		const gaCounterFieldSettings =
			userFiledsList.items.find(
				field => field.ID === SettingsModel[settingsKeyCounter],
			) || {};

		if ('EDIT_FORM_LABEL' in gaCounterFieldSettings)
			gaCounterUserFieldName =
				gaCounterFieldSettings.EDIT_FORM_LABEL[CONFIG.LANG.toLocaleLowerCase()];
	}

	const gaCounterInUse = gaCounterCustom ? gaCounterCustom : gaCounterFromGa;
	/*
	useEffect(() => {
		setTimeout(() => {
			fitWindow();
		}, 1000);
	}, [categoryLayerOpenIdState]);
	*/

	return (
		<>
			<Grid>
				<Grid item xs={6}>
					<Typography variant="h5">{label}</Typography>
				</Grid>
				<div className="blockquote-warning">
					<p>ℹ️ {description}</p>
				</div>
				<Grid item xs={12}>
					{categoryListFiltred.items.map((category, i) => (
						<Button
							variant="outlined"
							color="inherit"
							onClick={handleOpenCategory.bind(null, category.ID)}
							key={i}
							className={classes.categoryButton}
						>
							<EditIcon fontSize={'small'} />
							{category.NAME}
						</Button>
					))}
				</Grid>
			</Grid>
			{categoryLayerOpenIdState || categoryLayerOpenIdState === 0 ? (
				<DrawerStyled
					anchor={'right'}
					open={true}
					onClose={setCategoryLayerOpenIdState.bind(null, false)}
				>
					<Grid container color={'secondary'}>
						<Grid xs={12} item>
							<AppBar position="static" color="default">
								<Typography variant="h5" style={{ marginTop: '10px' }}>
									{'  '}
									<Button
										color="secondary"
										onClick={setCategoryLayerOpenIdState.bind(null, false)}
									>
										<CloseIcon />
									</Button>
									{categoryNameState.toUpperCase()}
									{gaCounterInUse ? (
										<>
											{' '}
											<Chip
												avatar={<Avatar src="/resources/YaMetricLogo.png" />}
												label={gaCounterInUse}
												color={'primary'}
											/>
										</>
									) : null}
									<br />
								</Typography>
							</AppBar>
						</Grid>

						<Grid container style={{ marginTop: 0 }}>
							<Grid item xs={5} className={classes.gridGoals}>
								<TableContainer className={classes.tableContainerGoals}>
									<Table className={classes.table} stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell>
													<div
														className="blockquote-warning"
														style={{
															fontSize: '14px',
															marginTop: '5px',
														}}
													>
														ℹ️ {t('Category description')}
													</div>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell className={classes.tdExpertMode}>
													<YaProfile
														SettingsModel={SettingsModel}
														settingKeyStages={settingKeyStages}
														settingKeyCategories={settingKeyCategories}
														categoryId={categoryLayerOpenIdState}
														loadingState={loadingState}
														isFetching={isFetching}
														setLoadingState={setLoadingState}
														categoryList={categoryListFiltred}
														stageSave={stageSave}
														stageList={stageListFiltred}
														stagesReset={stagesReset}
														expertMode={expertModeState}
													/>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={7} className={classes.gridStage}>
								<TableContainer className={classes.tableContainerStages}>
									<Table className={classes.table} stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell>
													<div
														className="blockquote-warning"
														style={{
															fontSize: '14px',
															marginTop: '5px',
															marginLeft: '5px',
														}}
													>
														ℹ️ {t('Stage settings description')}
													</div>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{categoryLayerOpenIdState in stageListFiltred.fetching &&
												stageListFiltred.fetching[categoryLayerOpenIdState] ? (
												<TableRow>
													<TableCell>
														<Skeleton animation={'wave'} />
													</TableCell>
												</TableRow>
											) : (
												stageListFiltred.items
													.filter(item =>
														!('CATEGORY_ID' in item)
															? true
															: item.CATEGORY_ID == categoryLayerOpenIdState,
													)
													.map((stage, i) => (
														<StageItem
															stage={stage}
															settingKeyStages={settingKeyStages}
															stageReset={stageReset}
															isFetching={isFetching}
															key={i}
															stageSave={stageSave}
															expertMode={expertModeState}
															lead={lead}
														/>
													))
											)}
										</TableBody>
									</Table>
								</TableContainer>
								<TableContainer></TableContainer>
							</Grid>
						</Grid>
					</Grid>
				</DrawerStyled>
			) : null}
		</>
	);
};

export default Category;
