import React, { useState } from 'react';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';

/** Other components */
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

/** Custom components */
import ProgressForButton from 'components/common/CircularProgressStyled';

/** Sub components */

/** Actions */

/** Helpers */
import { useTranslationWithParse } from 'helpers/translation';
import { sanitizeString } from 'helpers/utils';

const CallTrackingItem = ({
	callTrackerItem,
	SettingsModel,
	isFetching,
	setLoadingState,
	loadingState,
}) => {
	const { t } = useTranslationWithParse();

	const [callTrackingItemEditState, setCallTrackingItemEditState] = useState(
		{},
	);

	const callTrackingItemEdit = (field, event) => {
		setCallTrackingItemEditState({
			...callTrackingItemEditState,
			[field]: event.target.value,
		});
	};

	const deleteCallTrackingItem = () => {
		setCallTrackingItemEditState({});
		setLoadingState({
			...loadingState,
			call_tracking: { [callTrackerItem.id]: true },
		});
		SettingsModel.deleteCallTracking(callTrackerItem.id);
		SettingsModel.saveSettings();
	};

	const callTrackingSave = () => {
		SettingsModel.callTracking = {
			id: callTrackerItem.id || null,
			phone: callTrackingItemEditState.phone || callTrackerItem.phone || '',
			poolName: 'poolName' in callTrackingItemEditState ? callTrackingItemEditState.poolName : callTrackerItem.poolName,
		};

		if (callTrackerItem?.id) {
			setLoadingState({
				...loadingState,
				call_tracking: { [callTrackerItem.id]: true },
			});
		}

		setCallTrackingItemEditState({});
		SettingsModel.saveSettings();
	};

	const isCallTrackingFetching = isFetching(
		'call_tracking',
		callTrackerItem.id,
	);

	const poolNameValue =
		'poolName' in callTrackingItemEditState
			? callTrackingItemEditState.poolName
			: callTrackerItem.poolName ? callTrackerItem.poolName : '';

	let errorPoolNameText = /^[0-9A-Za-z\-\_]+$/gm.test(poolNameValue) || poolNameValue === ''
		? ''
		: t('Phone track pool name error');

	const phoneValue =
		'phone' in callTrackingItemEditState
			? callTrackingItemEditState.phone
			: callTrackerItem.phone;

	let errorPhoneText = phoneValue.length === 0 ? t('Phone track empty') : '';

	return (
		<TableRow style={{ height: 155 }}>
			{isFetching('call_tracking', callTrackerItem.id) ? (
				<>
					<TableCell>
						<Skeleton animation={'wave'} />
					</TableCell>
					<TableCell>
						<Skeleton animation={'wave'} />
					</TableCell>
				</>
			) : (
				<>
					<TableCell>
						<InputLabel>Phone</InputLabel>
						<TextField
							error={errorPhoneText}
							value={phoneValue}
							label=""
							margin="normal"
							variant="outlined"
							onChange={callTrackingItemEdit.bind(this, 'phone')}
							style={{ borderColor: 'red' }}
							disabled={false}
							helperText={errorPhoneText}
							style={{ height: 80 }}
						/>
					</TableCell>
					<TableCell>
						<InputLabel>Pool Name</InputLabel>
						<TextField
							error={errorPoolNameText}
							value={poolNameValue}
							label=""
							margin="normal"
							variant="outlined"
							onChange={callTrackingItemEdit.bind(this, 'poolName')}
							style={{ borderColor: 'red' }}
							disabled={false}
							helperText={errorPoolNameText}
							style={{ height: 80, width: 300 }}
						/>
					</TableCell>
				</>
			)}

			<TableCell style={{ width: 30 }}>
				<Button
					onClick={callTrackingSave}
					color={'secondary'}
					disabled={!phoneValue || isCallTrackingFetching || errorPhoneText || errorPoolNameText}
				>
					<SaveIcon />
					{isCallTrackingFetching ? <ProgressForButton size={24} /> : null}
				</Button>
				{callTrackerItem?.id != 'default' ? (
					<Button color="inherit" onClick={deleteCallTrackingItem}>
						<ClearIcon />
					</Button>
				) : (
					<Box style={{ height: 32 }} />
				)}
			</TableCell>
		</TableRow>
	);
};

export default CallTrackingItem;
