module.exports = {
	get: cname => {
		if (!cname) throw new Error(`Cookie name should be specify`);

		const name = cname + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');

		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	},
	token: () => {
		return module.exports.get(
			`${process.env.REACT_APP_B24_AUTH_COOKIE_NAME}_${module.exports.get(
				'b242ya_member_id',
			)}`,
		);
	},
	placement: () => {
		return module.exports.get(
			`${process.env.REACT_APP_B24_PLACEMEMT_COOKIE_NAME}_${module.exports.get(
				'b242ya_member_id',
			)}`,
		);
	},
	crmEntityId: () => {
		return module.exports.get(
			`${process.env.REACT_APP_B24_CRM_ENTITY_ID_COOKIE_NAME
			}_${module.exports.get('b242ya_member_id')}`,
		);
	},
};
