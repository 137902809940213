import { all, fork, spawn } from 'redux-saga/effects';

import {
	dealsCategoryListFlow,
	dealsCategoryStageListFlow,
	dealsUserFieldListFlow,
	dealCrmUpdateFlow,
} from './deals';

import {
	leadsCategoryListFlow,
	leadsCategoryStageListFlow,
	leadsUserFieldListFlow,
	leadCrmUpdateFlow,
} from './leads';

import {
	gaAccountSummariesFlow,
	gaCustomDataSourcesFlow,
	gaGoalsListFlow,
} from './ga';
import { fbAdsAccountsFlow } from './fb';
import { vkAdsAccountsFlow } from './vk';
import { yaAdsAccountsFlow, yaCountersFlow, yaGoalsFlow } from './ya';

import {
	settingsListFlow,
	settingsInitFlow,
	settingsUpdateFlow,
	settingsPlacementFlow,
} from './settings';

import { sourcesListFlow } from './sources';
import { logsListFlow } from './logs';
import { trackIdGetFlow } from './trackId';

import {
	gaAuthCheckFlow,
	gaAuthRevokeFlow,
	fbAuthCheckFlow,
	fbAuthRevokeFlow,
	vkAuthCheckFlow,
	vkAuthRevokeFlow,
	yaAuthCheckFlow,
	yaAuthRevokeFlow,
} from './auth';

import { dataLensConnectionFlow } from './dataLensConnection';

export default function* rootSaga() {
	try {
		yield all([
			fork(dealsCategoryListFlow),
			fork(dealsCategoryStageListFlow),
			fork(dealCrmUpdateFlow),
			fork(dealsUserFieldListFlow),

			fork(leadsCategoryListFlow),
			fork(leadsCategoryStageListFlow),
			fork(leadCrmUpdateFlow),
			fork(leadsUserFieldListFlow),

			fork(settingsListFlow),
			fork(settingsInitFlow),
			fork(settingsUpdateFlow),
			fork(settingsPlacementFlow),

			fork(gaAccountSummariesFlow),
			fork(gaCustomDataSourcesFlow),
			fork(gaGoalsListFlow),

			fork(fbAdsAccountsFlow),
			fork(vkAdsAccountsFlow),
			fork(yaAdsAccountsFlow),
			fork(yaCountersFlow),
			fork(yaGoalsFlow),

			fork(sourcesListFlow),
			fork(logsListFlow),
			fork(trackIdGetFlow),

			fork(gaAuthCheckFlow),
			fork(gaAuthRevokeFlow),
			fork(fbAuthCheckFlow),
			fork(fbAuthRevokeFlow),
			fork(vkAuthCheckFlow),
			fork(vkAuthRevokeFlow),
			fork(yaAuthCheckFlow),
			fork(yaAuthRevokeFlow),

			fork(dataLensConnectionFlow),
		]);
		//console.log('all sagas complete');
	} catch (e) {
		//console.log('error all sagas', e);
	}
}
