import React, { useState, useCallback } from 'react';
import { useDrop } from 'react-dnd';

/** Tables  */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

/** Icons  */
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

/** Other components */
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import Chip from 'components/common/ChipStyled';
import Typography from '@material-ui/core/Typography';
import {
	deepOrange,
	deepPurple,
	green,
	orange,
} from '@material-ui/core/colors';

/** Custom components */
import SaveButton from 'components/common/SaveButton';
import DrawerStyled from 'components/common/DrawerStyled';
import CircularProgressStyled from 'components/common/CircularProgressStyled';

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	row: props => ({
		backgroundColor: props.isActive
			? 'white'
			: props.canDrop
				? '#eef2f4'
				: 'white',
		height: 170,
	}),
	cellStage: { width: 370, borderBottom: 0 },
	cellGoal: { borderBottom: 0 },
	chipStageWithGoal: {
		backgroundColor: '',
	},
	chipStageWithOutGoal: {
		backgroundColor: '', //https://material-ui.com/customization/color/
	},
	tdEditControls: {
		width: 30,
	},
}));

/**
 * https://material-ui.com/components/dividers/#middle-dividers
 * ДЛЯ КАРТОЧКИ СТАИИ
 */
const StageItem = ({
	stage,
	isFetching,
	stageReset,
	settingKeyStages,
	stageSave,
	expertMode,
	lead,
}) => {
	const [{ canDrop, isOver }, drop] = useDrop({
		accept: CONFIG.GOAL_DRAG_TYPE,
		drop: () => ({
			stage,
		}),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	const deleteHandler = () => {
		stageReset(stage.STATUS_ID);
	};

	const isActive = canDrop && isOver;
	const classes = useStyles({ isActive, canDrop, isOver });

	const [stageEditState, setStageEditState] = useState(null);
	const [e2eStatusState, setE2EStatusState] = useState(
		stage.YA_E2E_STATUS
			? stage.YA_E2E_STATUS
			: stage.IS_CONVERSION === 'Y' && !lead
				? 'PAID'
				: 'IN_PROGRESS',
	);

	const stageEdit = (field, event) => {
		setStageEditState({
			...stageEditState,
			[field]: event.target.value,
		});
	};

	const gaGoalChip =
		CONFIG.GA_GOAL_NAME in stage && stage[CONFIG.GA_GOAL_NAME] ? (
			<>
				<SubdirectoryArrowRightIcon fontSize={'small'} />
				<Chip
					avatar={<Avatar src="/resources/YaMetricLogo.png" />}
					label={`${stage[CONFIG.GA_GOAL_NAME]}`}
					clickable
					color={canDrop ? 'secondary' : 'primary'}
					deleteIcon={<HighlightOffIcon />}
					onDelete={deleteHandler}
					disabled={false}
				/>
			</>
		) : canDrop ? (
			''
		) : null;

	const yaGoalChip =
		CONFIG.YA_GOAL_NAME in stage && stage[CONFIG.YA_GOAL_NAME] ? (
			<>
				<SubdirectoryArrowRightIcon fontSize={'small'} />
				<Chip
					avatar={<Avatar src="/resources/YaMetricLogo.png" />}
					label={`${stage[CONFIG.YA_GOAL_NAME]}: ${stage[CONFIG.YA_GOAL_ID]}`}
					clickable
					color={canDrop ? 'secondary' : 'primary'}
					deleteIcon={<HighlightOffIcon />}
					onDelete={deleteHandler}
					disabled={false}
				/>
			</>
		) : canDrop ? (
			''
		) : (
			<>
				<SubdirectoryArrowRightIcon fontSize={'small'} />
				<Chip
					label={'цель не привязана'}
					style={{ backgroundColor: orange[100] }}
					variant="outlined"
				/>
			</>
		);

	const handleE2eStatusChange = event => {
		const e2eStatus = event.target.value;
		setE2EStatusState(e2eStatus);

		const params = {
			STATUS_ID: stage.STATUS_ID,
			IS_CONVERSION: e2eStatus === 'PAID' ? 'Y' : 'N',
			yaGoalId: 'YA_GOAL_ID' in stage ? stage.YA_GOAL_ID : '',
			yaGoalName: 'YA_GOAL_NAME' in stage ? stage.YA_GOAL_NAME : '',
			yaGoalUrl: 'YA_GOAL_URL' in stage ? stage.YA_GOAL_URL : '',
			e2eStatus,
		};

		stageSave({ ...stage, ...params });
	};

	const controlE2EProps = item => ({
		checked: e2eStatusState === item,
		onChange: handleE2eStatusChange,
		value: item,
		name: 'color-radio-button-demo',
		inputProps: { 'aria-label': item },
	});

	return (
		<TableRow ref={drop} className={classes.row}>
			{/** make edit mode like in SourceItem */}
			{!stageEditState ? (
				<TableCell>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell className={classes.cellStage}>
									{isFetching(settingKeyStages, stage.STATUS_ID) ? (
										<>
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
										</>
									) : (
										<>
											<Typography gutterBottom variant="h6">
												<Chip
													avatar={<Avatar src="/resources/B24Logo.png" />}
													label={stage.NAME.toLocaleLowerCase()}
													color={stage?.COLOR ? '' : ''}
													style={{
														background: stage?.COLOR
															? stage.COLOR
															: stage.IS_CONVERSION === 'Y' ||
																stage.YA_E2E_STATUS === 'PAID'
																? '#dbf199'
																: '#ace9fb',
													}}
												/>
											</Typography>
											{gaGoalChip}
											{yaGoalChip}
										</>
									)}
								</TableCell>

								<TableCell className={classes.cellGoal}>
									{isFetching(settingKeyStages, stage.STATUS_ID) ? (
										<>
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
											<Skeleton animation={'wave'} />
										</>
									) : (
										<>
											<span style={{ fontSize: '10px' }}>
												<Radio {...controlE2EProps('IN_PROGRESS')} />{' '}
												<Chip
													avatar={<Avatar src="/resources/YaMetricLogo.png" />}
													label={'IN_PROGRESS'}
													variant="outlined"
													style={{
														backgroundColor:
															e2eStatusState === 'IN_PROGRESS'
																? green[200]
																: '',
													}}
												/>
												<br />
												<Radio
													{...controlE2EProps('PAID')}
													color="secondary"
													disabled={lead}
												/>
												<Chip
													avatar={<Avatar src="/resources/YaMetricLogo.png" />}
													disabled={lead}
													label={'PAID'}
													variant="outlined"
													style={{
														backgroundColor:
															e2eStatusState === 'PAID' ? green[200] : '',
													}}
												/>
												<br />
												<Radio
													{...controlE2EProps('CANCELLED')}
													color="success"
												/>{' '}
												<Chip
													avatar={<Avatar src="/resources/YaMetricLogo.png" />}
													label={'CANCELLED'}
													variant="outlined"
													style={{
														backgroundColor:
															e2eStatusState === 'CANCELLED' ? green[200] : '',
													}}
												/>
												<br />
												<Radio
													{...controlE2EProps('SPAM')}
													color="default"
												/>{' '}
												<Chip
													avatar={<Avatar src="/resources/YaMetricLogo.png" />}
													label={'SPAM'}
													variant="outlined"
													style={{
														backgroundColor:
															e2eStatusState === 'SPAM' ? green[200] : '',
													}}
												/>
												<br />
												<Radio {...controlE2EProps('DISABLED')} />
												<Chip
													label={'не передавать статус'}
													variant="outlined"
													style={{
														backgroundColor:
															e2eStatusState === 'DISABLED' ? orange[200] : '',
													}}
												/>
											</span>
											{/*
											<div>
												{stage[CONFIG.YA_GOAL_NAME] ? (
													<Chip
														variant={'outlined'}
														color={'primary'}
														label={`name: ${stage[CONFIG.YA_GOAL_NAME]}`}
														size="small"
													/>
												) : (
													''
												)}
											</div>
											<div>
												{stage[CONFIG.YA_GOAL_ID] ? (
													<Chip
														variant={'outlined'}
														color={'primary'}
														label={`id: ${stage[CONFIG.YA_GOAL_ID]}`}
														size="small"
													/>
												) : (
													''
												)}
											</div>
											<div>
												{stage[CONFIG.GA_CATEGORY] ? (
													<Chip
														variant={'outlined'}
														color={'primary'}
														label={`category: ${stage[CONFIG.GA_CATEGORY]}`}
														size="small"
													/>
												) : (
													''
												)}
											</div>
											<div>
												{stage[CONFIG.GA_ACTION] ? (
													<Chip
														variant={'outlined'}
														color={'primary'}
														label={`action: ${stage[CONFIG.GA_ACTION]}`}
														size="small"
													/>
												) : (
													''
												)}
											</div>
											<div>
												{stage[CONFIG.GA_LABEL] ? (
													<Chip
														variant={'outlined'}
														color={'primary'}
														label={`label: ${stage[CONFIG.GA_LABEL]}`}
														size="small"
													/>
												) : (
													''
												)}
												</div>
												*/}
										</>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableCell>
			) : (
				<TableCell>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<TextField
										label="CATEGORY"
										value={`${CONFIG.GA_CATEGORY in stageEditState
												? stageEditState[CONFIG.GA_CATEGORY]
												: stage[CONFIG.GA_CATEGORY]
													? stage[CONFIG.GA_CATEGORY]
													: ''
											}`}
										onChange={stageEdit.bind(this, CONFIG.GA_CATEGORY)}
										margin="normal"
										variant="outlined"
										size={'small'}
									/>
								</TableCell>
								<TableCell>
									<TextField
										value={`${CONFIG.GA_ACTION in stageEditState
												? stageEditState[CONFIG.GA_ACTION]
												: stage[CONFIG.GA_ACTION]
													? stage[CONFIG.GA_ACTION]
													: ''
											}`}
										label="ACTION"
										onChange={stageEdit.bind(this, CONFIG.GA_ACTION)}
										margin="normal"
										variant="outlined"
										size={'small'}
									/>
								</TableCell>
								<TableCell>
									<TextField
										value={`${CONFIG.GA_LABEL in stageEditState
												? stageEditState[CONFIG.GA_LABEL]
												: stage[CONFIG.GA_LABEL]
													? stage[CONFIG.GA_LABEL]
													: ''
											}`}
										label="LABEL"
										onChange={stageEdit.bind(this, CONFIG.GA_LABEL)}
										margin="normal"
										variant="outlined"
										size={'small'}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableCell>
			)}
		</TableRow>
	);
};

export default StageItem;
