import { SettingsModelStructureError } from '../error/settingsError';
import { randomHash } from '../helpers/utils';

export class Settings {
	_dealsStages = [];
	_dealsCategories = [];
	_dealsGaCidField = null;
	_dealsCd = [];
	_dealsGaUidField = null;
	_dealsGaCounterField = null;

	_leadsStages = [];
	_leadsCategories = [];
	_leadsGaCidField = null;
	_leadsCd = [];
	_leadsGaUidField = null;
	_leadsGaCounterField = null;

	_cidGenerator = false;
	_sources = [];
	_emailTracking = [];
	_callTracking = [];
	_costs = [];
	_gaCounter = null;
	_trackId = false;
	_notifications = true;

	_costs = [];

	_dataLensSettings = {
		dbHost: '',
		dbLogin: '',
		dbName: '',
		dbPort: '',
		dbPass: '',
	};

	_api = {};

	constructor(settings, reduxActions) {
		this.checkTypes(settings);

		this.dealsStages = settings.deals_stages;
		this.dealsCategories = settings.deals_category;
		this.dealsCd = settings.deals_cd;
		this.dealsGaCidField = settings.deals_ga_cid_field;
		this.dealsGaUidField = settings.deals_ga_uid_field;
		this.dealsGaCounterField = settings.deals_ga_counter_field;

		this.leadsStages = settings.leads_stages;
		this.leadsCategories = settings.leads_category;
		this.leadsCd = settings.leads_cd;
		this.leadsGaCidField = settings.leads_ga_cid_field;
		this.leadsGaUidField = settings.leads_ga_uid_field;
		this.leadsGaCounterField = settings.leads_ga_counter_field;

		this.sources = settings.sources;
		this.emailTracking = settings.email_tracking;
		this.callTracking = settings.call_tracking;
		this.costs = settings.costs;
		this.cidGenerator = settings.cid_generator;
		this.gaCounter = settings.ga_counter;
		this.trackId = settings.track_id;
		this.notifications = settings.notifications;

		this.dataLensSettings = settings.data_lens_settings;

		this.api = reduxActions;
	}

	checkTypes(settings) {
		if (!('deals_stages' in settings) || !Array.isArray(settings.deals_stages))
			throw new SettingsModelStructureError(
				'deals_stages should be specify and should be an array',
			);

		if (
			!('deals_category' in settings) ||
			!Array.isArray(settings.deals_category)
		)
			throw new SettingsModelStructureError(
				'deals_category should be specify and should be an array',
			);

		if (
			!('deals_ga_cid_field' in settings) ||
			typeof settings['deals_ga_cid_field'] !== 'string'
		)
			throw new SettingsModelStructureError(
				'deals_ga_cid_field should be specify and should be a string',
			);

		if (
			!('deals_ga_uid_field' in settings) ||
			typeof settings['deals_ga_uid_field'] !== 'string'
		)
			throw new SettingsModelStructureError(
				'deals_ga_uid_field should be specify and should be a string',
			);

		if (!('deals_cd' in settings) || !Array.isArray(settings.deals_cd))
			throw new SettingsModelStructureError(
				'deals_cd should be specify and should be an array',
			);

		if (!('deals_ga_counter_field' in settings))
			throw new SettingsModelStructureError(
				'deals_ga_counter_field should be specify',
			);

		if (!('sources' in settings) || !Array.isArray(settings.sources))
			throw new SettingsModelStructureError(
				'sources should be specify and should be an array',
			);

		if (
			!('email_tracking' in settings) ||
			!Array.isArray(settings.email_tracking)
		)
			throw new SettingsModelStructureError(
				'email_tracking should be specify and should be an array',
			);

		if (!('costs' in settings) || !Array.isArray(settings.costs))
			throw new SettingsModelStructureError(
				'costs should be specify and should be an array',
			);

		if (
			!('cid_generator' in settings) ||
			typeof settings['cid_generator'] !== 'boolean'
		)
			throw new SettingsModelStructureError(
				'cid_generator should be specify and should be a boolean',
			);
	}

	dealsCdDelete(id) {
		this._dealsCd = this.dealsCd.filter(item => item.id !== '' + id);
	}

	set dealsCd(data) {
		data.forEach(item => this._dealsCd.push(item));
	}

	get dealsCd() {
		return this._dealsCd;
	}

	set dealsCategories(data) {
		if (Array.isArray(data)) {
			this.dealsCategories.forEach((category, i) => {
				data.forEach(data => {
					if (data.ID == category.ID) {
						this._dealsCategories[i] = {
							...this._dealsCategories[i],
							...data,
						};
					}
				});
			});

			data.forEach(data => {
				if (!this.dealsCategories.find(category => category.ID == data.ID))
					this._dealsCategories.push(data);
			});
		}

		if (!Array.isArray(data)) {
			this.dealsCategories.forEach((category, i) => {
				if (data.ID == category.ID) {
					this._dealsCategories[i] = {
						...this._dealsCategories[i],
						...data,
					};
				}
			});

			if (!this.dealsCategories.find(category => category.ID == data.ID))
				this._dealsCategories.push(data);
		}
	}

	get dealsCategories() {
		return this._dealsCategories || [];
	}

	set dealsGaCidField(value) {
		this._dealsGaCidField = value;
	}

	get dealsGaCidField() {
		return this._dealsGaCidField || '';
	}

	set dealsGaUidField(value) {
		this._dealsGaUidField = value;
	}

	get dealsGaUidField() {
		return this._dealsGaUidField || '';
	}

	set dealsGaCounterField(value) {
		this._dealsGaCounterField = value;
	}

	get dealsGaCounterField() {
		return this._dealsGaCounterField || '';
	}

	get dealsStages() {
		return this._dealsStages || [];
	}

	set dealsStages(data) {
		if (Array.isArray(data)) {
			this.dealsStages.forEach((stage, i) => {
				data.forEach(data => {
					if (data.STATUS_ID == stage.STATUS_ID) {
						this._dealsStages[i] = {
							...this._dealsStages[i],
							...data,
						};
					}
				});
			});

			data.forEach(data => {
				if (!this.dealsStages.find(stage => stage.STATUS_ID == data.STATUS_ID))
					this._dealsStages.push(data);
			});
		}

		if (!Array.isArray(data)) {
			this.dealsStages.forEach((stage, i) => {
				if (data.STATUS_ID == stage.STATUS_ID) {
					this._dealsStages[i] = {
						...this._dealsStages[i],
						...data,
					};
				}
			});

			if (!this.dealsStages.find(stage => stage.STATUS_ID == data.STATUS_ID))
				this._dealsStages.push(data);
		}
	}

	leadsCdDelete(id) {
		this._leadsCd = this.leadsCd.filter(item => item.id !== '' + id);
	}

	set leadsCd(data) {
		data.forEach(item => this._leadsCd.push(item));
	}

	get leadsCd() {
		return this._leadsCd;
	}

	set leadsCategories(data) {
		if (Array.isArray(data)) {
			this.leadsCategories.forEach((category, i) => {
				data.forEach(data => {
					if (data.ID == category.ID) {
						this._leadsCategories[i] = {
							...this._leadsCategories[i],
							...data,
						};
					}
				});
			});

			data.forEach(data => {
				if (!this.leadsCategories.find(category => category.ID == data.ID))
					this._leadsCategories.push(data);
			});
		}

		if (!Array.isArray(data)) {
			this.leadsCategories.forEach((category, i) => {
				if (data.ID == category.ID) {
					this._leadsCategories[i] = {
						...this._leadsCategories[i],
						...data,
					};
				}
			});

			if (!this.leadsCategories.find(category => category.ID == data.ID))
				this._leadsCategories.push(data);
		}
	}

	get leadsCategories() {
		return this._leadsCategories || [];
	}

	set leadsGaCidField(value) {
		this._leadsGaCidField = value;
	}

	get leadsGaCidField() {
		return this._leadsGaCidField || '';
	}

	set leadsGaUidField(value) {
		this._leadsGaUidField = value;
	}

	get leadsGaUidField() {
		return this._leadsGaUidField || '';
	}

	set leadsGaCounterField(value) {
		this._leadsGaCounterField = value;
	}

	get leadsGaCounterField() {
		return this._leadsGaCounterField || '';
	}

	get leadsStages() {
		return this._leadsStages || [];
	}

	set leadsStages(data) {
		if (Array.isArray(data)) {
			this.leadsStages.forEach((stage, i) => {
				data.forEach(data => {
					if (data.STATUS_ID == stage.STATUS_ID) {
						this._leadsStages[i] = {
							...this._leadsStages[i],
							...data,
						};
					}
				});
			});

			data.forEach(data => {
				if (!this.leadsStages.find(stage => stage.STATUS_ID == data.STATUS_ID))
					this._leadsStages.push(data);
			});
		}

		if (!Array.isArray(data)) {
			this.leadsStages.forEach((stage, i) => {
				if (data.STATUS_ID == stage.STATUS_ID) {
					this._leadsStages[i] = {
						...this._leadsStages[i],
						...data,
					};
				}
			});

			if (!this.leadsStages.find(stage => stage.STATUS_ID == data.STATUS_ID))
				this._leadsStages.push(data);
		}
	}

	set sources(data) {
		if (Array.isArray(data)) {
			this.sources.forEach((source, i) => {
				data.forEach(data => {
					if (data.STATUS_ID == source.STATUS_ID) {
						this._sources[i] = {
							...this._sources[i],
							...data,
						};
					}
				});
			});

			data.forEach(data => {
				if (!this.sources.find(source => source.STATUS_ID == data.STATUS_ID))
					this._sources.push(data);
			});
		}

		if (!Array.isArray(data)) {
			this.sources.forEach((source, i) => {
				if (data.STATUS_ID == source.STATUS_ID) {
					this._sources[i] = {
						...this._sources[i],
						...data,
					};
				}
			});

			if (!this.sources.find(source => source.STATUS_ID == data.STATUS_ID))
				this._sources.push(data);
		}
	}

	get sources() {
		return this._sources;
	}

	deleteCallTracking(id) {
		this._callTracking = this.callTracking.filter(item => item.id !== id);
	}

	set callTracking(data) {
		if (Array.isArray(data)) {
			data.forEach((callTrackingItem, i) => {
				if (
					!callTrackingItem?.id ||
					callTrackingItem.id === null ||
					callTrackingItem.id === 'default'
				)
					callTrackingItem['id'] = randomHash();
			});

			this._callTracking = data;
		}

		if (!Array.isArray(data)) {
			this.callTracking.forEach((callTrackingItem, i) => {
				if (data.id == callTrackingItem.id) {
					this._callTracking[i] = {
						...this._callTracking[i],
						...data,
					};
				}
			});

			if (
				!this.callTracking.find(
					callTrackingItem => callTrackingItem.id == data.id,
				)
			) {
				if (!data?.id || data.id === null || data.id === 'default')
					data.id = randomHash();

				this._callTracking.push(data);
			}
		}
	}

	get callTracking() {
		return this._callTracking || [];
	}

	deleteEmailTracking(id) {
		this._emailTracking = this.emailTracking.filter(item => item.id !== id);
	}

	set emailTracking(data) {
		if (Array.isArray(data)) {
			data.forEach((emailTrackingItem, i) => {
				if (
					!emailTrackingItem?.id ||
					emailTrackingItem.id === null ||
					emailTrackingItem.id === 'default'
				)
					emailTrackingItem['id'] = randomHash();
			});

			this._emailTracking = data;
		}

		if (!Array.isArray(data)) {
			this.emailTracking.forEach((emailTrackingItem, i) => {
				if (data.id == emailTrackingItem.id) {
					this._emailTracking[i] = {
						...this._emailTracking[i],
						...data,
					};
				}
			});

			if (
				!this.emailTracking.find(
					emailTrackingItem => emailTrackingItem.id == data.id,
				)
			) {
				if (!data?.id || data.id === null || data.id === 'default')
					data.id = randomHash();

				this._emailTracking.push(data);
			}
		}
	}

	get emailTracking() {
		return this._emailTracking || [];
	}

	costDelete(id) {
		this._costs = this.costs.filter(item => item.id !== '' + id);
	}

	costDeleteBySource(source) {
		this._costs = this.costs.filter(item => item.source !== source);
	}

	set costs(data) {
		if (Array.isArray(data)) {
			this.costs.forEach((cost, i) => {
				data.forEach(data => {
					if (data.id == cost.id) {
						this._costs[i] = {
							...this._costs[i],
							...data,
						};
					}
				});
			});

			data.forEach(data => {
				if (!this.costs.find(source => source.id == data.id)) {
					if (!data?.id || data.id === null) data.id = randomHash();

					this._costs.push(data);
				}
			});
		}

		if (!Array.isArray(data)) {
			this.costs.forEach((cost, i) => {
				if (data.id == cost.id) {
					this._costs[i] = {
						...this._costs[i],
						...data,
					};
				}
			});

			if (!this.costs.find(source => source.id == data.id)) {
				if (!data?.id || data.id === null) data.id = randomHash();

				this._costs.push(data);
			}
		}
	}

	get costs() {
		return this._costs;
	}

	set cidGenerator(data) {
		this._cidGenerator = data;
	}

	get cidGenerator() {
		return this._cidGenerator;
	}

	set gaCounter(data) {
		this._gaCounter = data;
	}

	get gaCounter() {
		return this._gaCounter;
	}

	set trackId(data) {
		this._trackId = data;
	}

	get trackId() {
		return this._trackId;
	}

	set notifications(data) {
		this._notifications = data;
	}

	get notifications() {
		return this._notifications;
	}

	set api(data) {
		this._api = data;
	}

	get api() {
		return this._api || null;
	}

	set dataLensSettings(data) {
		this._dataLensSettings = data;
	}

	get dataLensSettings() {
		return this._dataLensSettings;
	}

	saveSettings() {
		this.api.update(this.settings);
	}

	get settings() {
		return {
			deals_stages: this.dealsStages,
			deals_category: this.dealsCategories,
			deals_ga_cid_field: this.dealsGaCidField,
			deals_ga_uid_field: this.dealsGaUidField,
			deals_ga_counter_field: this.dealsGaCounterField,
			deals_cd: this.dealsCd,

			leads_stages: this.leadsStages,
			leads_category: this.leadsCategories,
			leads_ga_cid_field: this.leadsGaCidField,
			leads_ga_uid_field: this.leadsGaUidField,
			leads_ga_counter_field: this.leadsGaCounterField,
			leads_cd: this.leadsCd,

			sources: this.sources,
			email_tracking: this.emailTracking,
			call_tracking: this.callTracking,
			costs: this.costs,
			ga_counter: this.gaCounter,
			track_id: this.trackId,
			notifications: this.notifications,
			cid_generator: this.cidGenerator,

			data_lens_settings: this.dataLensSettings,
		};
	}
}
