import {
	takeEvery,
	call,
	put,
	take,
	fork,
	cancel,
	select,
	cancelled,
	delay,
} from 'redux-saga/effects';

import { getLogs } from 'api/logs';

import {
	logsFetchSuccess,
	logsFetchError,
	LOGS_FETCH_START,
} from 'actions/logsActions';

export function* logsListFlow() {
	while (true) {
		const { payload } = yield take(LOGS_FETCH_START);

		try {
			const result = yield call(getLogs, payload);

			yield put(logsFetchSuccess(result.data));
		} catch (error) {
			yield put(logsFetchError(error));
		}
	}
}
