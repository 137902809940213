import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AlertDialog } from 'components/common/Dialog';
import SaveButton from 'components/common/SaveButton';

import ClearIcon from '@material-ui/icons/Clear';

import { makeStyles } from '@material-ui/core/styles';

import { Settings } from 'models/settings';

import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';

/** Actions */
import { dataLensCheckDbStart } from 'actions/dataLensConnectionAction';

/** Helpers */
import { fitWindow } from 'helpers/utils';
import CONFIG from 'helpers/const';
import { useTranslationWithParse } from 'helpers/translation';

const useStyles = makeStyles(theme => ({}));

const Datalens = ({
	SettingsModel,
	isFetching,
	setLoadingState,
	loadingState,
}) => {
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const dispatch = useDispatch();

	const [warningDialogOpenState, setWarningDialogOpenState] = useState(false);
	const [okDialogOpenState, setOkDialogOpenState] = useState(false);
	const [resetDialogOpenState, setResetDialogOpenState] = useState(false);

	const dataLensConnectionData = useSelector(
		state => state.data_lens_connection,
	);

	const { result, error, errorText, fetching } = dataLensConnectionData;

	const dataLensCheckDb = params => dispatch(dataLensCheckDbStart(params));

	const {
		dbHost,
		dbPort,
		dbName,
		dbLogin,
		dbPass,
	} = SettingsModel.dataLensSettings;

	const [connectionEditState, setConnectionEditState] = useState({
		dbHost,
		dbPort,
		dbName,
		dbLogin,
		dbPass,
	});

	const settingsEdit = (field, event) => {
		setConnectionEditState({
			...connectionEditState,
			[field]: event.target.value,
		});
	};

	const settingsReset = () => {
		const resetedData = {
			dbHost: '',
			dbPort: '',
			dbName: '',
			dbLogin: '',
			dbPass: '',
		};

		setConnectionEditState(resetedData);

		SettingsModel.dataLensSettings = resetedData;
		SettingsModel.saveSettings();
		setResetDialogOpenState(true);
	};

	const handleWarningDialogClose = useCallback(() => {
		setWarningDialogOpenState(false);
	}, []);

	const handleOkDialogClose = useCallback(() => {
		setOkDialogOpenState(false);
	}, []);

	const handleResetDialogClose = useCallback(() => {
		setResetDialogOpenState(false);
	}, []);

	const dataLensCheckDbHandler = () => {
		dataLensCheckDb(connectionEditState);
	};

	useEffect(() => {
		if (
			Object.keys(connectionEditState).length > 0 &&
			Object.keys(connectionEditState).every(key => connectionEditState[key])
		)
			dataLensCheckDb(connectionEditState);
	}, []);

	useEffect(() => {
		if (result && !error && errorText === '') {
			SettingsModel.dataLensSettings = connectionEditState;
			SettingsModel.saveSettings();
			setOkDialogOpenState(true);
		}

		if (!result && error && errorText !== '') {
			setWarningDialogOpenState(true);
		}
	}, [dataLensConnectionData]);

	return (
		<Table>
			<TableBody>
				<AlertDialog
					open={warningDialogOpenState}
					title={`☠️ Ошибка проверки соединения с БД`}
					description={errorText}
					agreeHandler={handleWarningDialogClose}
					closeHandler={handleWarningDialogClose}
					showCancelButton={false}
					showOkButton={true}
				/>
				<AlertDialog
					open={okDialogOpenState}
					title={`😊 Соединение с БД для выгрузки данных проверено`}
					description={'Загрузка данных происходит раз в сутки. Состояние выгрузки вы можете отслеживать в вашей БД, в таблице b242ya_export_log'}
					agreeHandler={handleOkDialogClose}
					closeHandler={handleOkDialogClose}
					showCancelButton={false}
					showOkButton={true}
				/>
				<AlertDialog
					open={resetDialogOpenState}
					title={`🫂 Настройки соединения с БД сброшены`}
					description={'Выгрузка не производится'}
					agreeHandler={handleResetDialogClose}
					closeHandler={handleResetDialogClose}
					showCancelButton={false}
					showOkButton={true}
				/>
				<TableCell>
					<InputLabel>host</InputLabel>
					<TextField
						defaultValue={connectionEditState?.dbHost || dbHost}
						margin="normal"
						variant="outlined"
						onChange={settingsEdit.bind(this, 'dbHost')}
						value={connectionEditState['dbHost']}
						disabled={false}
					/>
					<br />
					<br />
					<br />
					<br />
				</TableCell>
				<TableCell>
					<InputLabel>port</InputLabel>
					<TextField
						defaultValue={connectionEditState?.dbPort || dbPort}
						margin="normal"
						variant="outlined"
						onChange={settingsEdit.bind(this, 'dbPort')}
						value={connectionEditState['dbPort']}
						disabled={false}
					/>
					<br />
					<br />
					<br />
					<br />
				</TableCell>
				<TableCell>
					<InputLabel>db name</InputLabel>
					<TextField
						defaultValue={connectionEditState?.dbName || dbName}
						margin="normal"
						variant="outlined"
						onChange={settingsEdit.bind(this, 'dbName')}
						value={connectionEditState['dbName']}
						disabled={false}
					/>
					<br />
					<br />
					<br />
					<br />
				</TableCell>
				<TableCell>
					<InputLabel>login</InputLabel>
					<TextField
						defaultValue={connectionEditState?.dbLogin || dbLogin}
						margin="normal"
						variant="outlined"
						onChange={settingsEdit.bind(this, 'dbLogin')}
						value={connectionEditState['dbLogin']}
						disabled={false}
					/>
					<br />
					<br />
					<br />
					<br />
				</TableCell>
				<TableCell>
					<InputLabel>pass</InputLabel>
					<TextField
						defaultValue={connectionEditState?.dbPass || dbPass}
						margin="normal"
						variant="outlined"
						onChange={settingsEdit.bind(this, 'dbPass')}
						value={connectionEditState['dbPass']}
						disabled={false}
					/>
					<br />
					<br />
					<br />
					<br />
				</TableCell>
				<TableCell>
					<SaveButton
						handler={dataLensCheckDbHandler}
						disabled={
							!Object.keys(connectionEditState).length > 0 ||
							fetching ||
							Object.keys(connectionEditState).some(
								key => !connectionEditState[key],
							)
						}
						isFetching={fetching}
						label={'Сохранить'}
					/>
					<br />
					<br />
					<Button
						onClick={settingsReset}
						variant="outlined"
						color="secondary"
						disabled={
							!Object.keys(connectionEditState).length > 0 ||
							fetching ||
							Object.keys(connectionEditState).some(
								key => !connectionEditState[key],
							)
						}
					>
						Отключить
						<ClearIcon />
					</Button>
				</TableCell>
			</TableBody>
		</Table>
	);
};

export default Datalens;
