import {
	DATALENS_CHECK_DB_START,
	DATALENS_CHECK_DB_SUCCESS,
	DATALENS_CHECK_DB_ERROR,
} from 'actions/dataLensConnectionAction';

export const initialState = {
	result: false,
	error: false,
	errorText: '',
};

export default (state = { ...initialState, fetching: false }, action) => {
	switch (action.type) {
		case DATALENS_CHECK_DB_START:
			return {
				...initialState,
				fetching: true,
			};

		case DATALENS_CHECK_DB_SUCCESS:
			return {
				...state,
				...action.payload,
				fetching: false,
			};

		case DATALENS_CHECK_DB_ERROR:
			return {
				...state,
				fetching: false,
			};
		default:
			return state;
	}
};
