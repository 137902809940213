import React, { useEffect } from 'react';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Tables  */

/** Other components */
import Typography from '@material-ui/core/Typography';
/** Icons  */

/** Components */
import Logs from 'components/pages/Logs/';

/** Custom component */

/** Actions */

/** Helpers */
import { fitWindow } from 'helpers/utils';

import { useTranslationWithParse } from 'helpers/translation';
import cookie from 'helpers/cookie';

const CrmDetailTab = () => {
  const { t } = useTranslationWithParse();

  useEffect(() => {
    setTimeout(() => {
      fitWindow();
    }, 1000);
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Typography variant="h5"> {t('Placement log title')}</Typography>
      </Grid>
      <Logs crmEntityId={cookie.crmEntityId()} />
    </Grid>
  );
};

export default CrmDetailTab;
