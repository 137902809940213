import React, {
	Component,
	useState,
	forwardRef,
	useEffect,
	useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

/** Grid */
import Grid from '@material-ui/core/Grid';

/** Icons  */
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import ViewColumn from '@material-ui/icons/ViewColumn';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

/** Other components */
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import {
	deepOrange,
	deepPurple,
	green,
	orange,
} from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';

/** Custom components */

/** Actions */
import { logsFetchStart } from 'actions/logsActions';

/** Helpers */
import { fitWindow } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	card: {
		maxWidth: 600,
	},
	cardBullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	cardTitle: {
		fontSize: 14,
	},
	cardPos: {
		marginBottom: 14,
	},
	table: {
		'& .MuiToolbar-root': {
			height: 40,
		},
		'& .MuiToolbar-regular': {
			minHeight: 0,
		},
		'& .MuiTableCell-root': {
			padding: 15,
		},
	},
}));

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Logs = params => {
	const classes = useStyles();
	const { t } = useTranslationWithParse();

	const { crmEntityId } = params;

	const logs = useSelector(state => state.logs);

	const dispatch = useDispatch();
	const fetchLogsList = () => dispatch(logsFetchStart({ crmEntityId }));

	useEffect(() => {
		fetchLogsList();
	}, []);

	const refreshLog = useCallback(() => {
		fetchLogsList();
	}, []);

	const renderStatusValue = useCallback((code, error_code) => {
		if (code === 'SUCCESS') return '✅';

		if (RegExp('(.*)WARNING_YA_COUNTER_NOT_FOUND(.*)').test(error_code))
			return '🚨';
		if (RegExp('(.*)WARNING(.*)').test(error_code)) return '💡';

		if (RegExp('(.*)ERROR(.*)').test(code)) return '🚨';

		if (code === 'SUSPEND' || code === 'START' || code == 'NEW') return '⌛';

		if (code === 'STARTED') return '⌛';
	});

	const renderTrStatus = useCallback(code => {
		if (code === 'COMPLETED') return <Chip label={code} color="primary" />;
		if (code === 'CANCELED') return <Chip label={code} color="secondary" />;
		return <Chip label={code} color="secondary" />;
	});

	/**
	 * Origin constants in
	 * nodejs container api/helpers/Constants
	 */
	const errorTypes = {
		ERROR_REDIS: t('ERROR_REDIS'),
		ERROR_MP_PARAMS: t('ERROR_MP_PARAMS'),
		WARNING_MP_PARAMS_NO_CLA: t('WARNING_MP_PARAMS_NO_CLA'),

		WARNING_YA_COUNTER_NOT_FOUND: t('WARNING_YA_COUNTER_NOT_FOUND'),
		WARNING_YA_CLIENT_ID_NOT_FOUND: t('WARNING_YA_CLIENT_ID_NOT_FOUND'),
		WARNING_YA_GOAL_ID_NOT_FOUND: t('WARNING_YA_GOAL_ID_NOT_FOUND'),

		ERROR_YA_API_CONVESRION: t('ERROR_YA_API_CONVESRION'),
		ERROR_MP_PARAMS_CID_REQUIRED: t('ERROR_MP_PARAMS_CID_REQUIRED'),
		ERROR_MP_PARAMS_TID_REQUIRED: t('ERROR_MP_PARAMS_TID_REQUIRED'),
		ERROR_MP_PARAMS_CID_WRONG_FORMAT: t('ERROR_API_DB'),
		ERROR_API_DB: t('ERROR_API_DB'),
		ERROR_B24_CALL: t('ERROR_B24_CALL'),
		ERROR_GET_SETTINGS: t('ERROR_GET_SETTINGS'),
		ERROR_SEND_TO_GA: t('ERROR_SEND_TO_GA'),
		ERROR_TRACKER_DATA: t('ERROR_TRACKER_DATA'),
	};

	const columns = [
		{
			title: '',
			field: 'status_code',
			filtering: false,
			width: 40,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => {
				return (
					<>{renderStatusValue(rowData.status_code, rowData.error_code)}</>
				);
			},
		},
		{
			title: '',
			filtering: false,
			field: 'mp_data_dump',
			width: 390,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => {
				let mp_data_dump = JSON.parse(rowData.mp_data_dump) || {};
				const fieldNames = {
					counter: 'счетчик',
					yaClid: 'Yandex clientId',
					contactExistsInYa: 'Yandex clientId из истории обращений',
					b24Clid: 'id контакта в CRM',
				};
				return Object.keys(mp_data_dump)
					.filter(key =>
						['counter', 'yaClid', 'contactExistsInYa', 'b24Clid'].some(
							param => param == key,
						),
					)
					.map((param, i) => {
						return (
							<span key={i}>
								<Chip
									label={`${fieldNames[param]}`}
									size="small"
									variant={'outlined'}
									style={
										!mp_data_dump[param] ||
											mp_data_dump[param] === 'false' ||
											(param === 'contactExistsInYa' &&
												mp_data_dump[param] === 'N')
											? { backgroundColor: orange[50] }
											: { backgroundColor: green[50] }
									}
								/>
								:{' '}
								{mp_data_dump[param] ? (
									<Chip label={mp_data_dump[param]} size="small" />
								) : (
									''
								)}
								<br />
							</span>
						);
					});
			},
		},
		{
			title: 'Сделка/Лид',
			field: 'crm_record_type',
			width: 100,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData => {
				const recordTypeName = rowData.crm_record_type;
				const avatarColor =
					recordTypeName === 'lead' ? deepOrange[500] : deepPurple[500];
				return (
					<Avatar
						style={
							recordTypeName === 'lead'
								? { backgroundColor: '#ff5722' }
								: { backgroundColor: '#673ab7' }
						}
					>
						{recordTypeName.slice(0, 1).toUpperCase()}
					</Avatar>
				);
			},
			lookup: { lead: 'lead', deal: 'deal' },
		},
		{
			title: 'Стадия воронки',
			field: 'crm_record_status',
			width: 130,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData => {
				let mp_data_dump = JSON.parse(rowData.mp_data_dump) || {};
				let stageName = rowData.crm_record_status;

				return (
					<Chip
						avatar={<Avatar src="/resources/B24Logo.png" />}
						label={stageName}
						variant="outlined"
					/>
				);
			},
		},
		{
			title: 'Оффлайн-конверсия (цель)',
			field: 'crm_goal',
			width: 130,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData => {
				let mp_data_dump = JSON.parse(rowData.mp_data_dump) || {};
				return mp_data_dump?.goal ? (
					<Chip
						avatar={<Avatar src="/resources/YaMetricLogo.png" />}
						label={mp_data_dump.goal}
						color={'primary'}
					/>
				) : (
					<Chip
						label={'цель не привязана'}
						style={{ backgroundColor: orange[100] }}
						variant="outlined"
					/>
				);
			},
		},
		{
			title: 'Статус для отчетов сквозной аналитики Метрики',
			field: 'crm_e2e_status',
			width: 130,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			filtering: false,
			render: rowData => {
				let mp_data_dump = JSON.parse(rowData.mp_data_dump) || {};
				return mp_data_dump?.e2eStatus ? (
					<Chip
						avatar={
							mp_data_dump.e2eStatus === 'DISABLED' ? (
								<></>
							) : (
								<Avatar src="/resources/YaMetricLogo.png" />
							)
						}
						label={
							mp_data_dump.e2eStatus === 'DISABLED'
								? 'статус не привязан'
								: mp_data_dump.e2eStatus
						}
						variant="outlined"
						style={
							mp_data_dump.e2eStatus === 'DISABLED'
								? { backgroundColor: orange[100] }
								: { backgroundColor: green[50] }
						}
					/>
				) : (
					''
				);
			},
		},
		{
			title: 'CRM ID',
			field: 'crm_record_id',
			filtering: false,
			width: 120,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => (
				<a href="#">
					<Chip label={`${rowData.crm_record_id}`} />
				</a>
			),
		},
		{
			title: 'LOG ID',
			field: 'id',
			filtering: false,
			width: 120,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
			render: rowData => (
				<a href="#">
					<Chip label={`${rowData.id}`} />
				</a>
			),
		},
		{
			title: 'CRM TITLE',
			field: 'crm_record_name',
			filtering: false,
			headerStyle: {
				fontSize: 12,
				fontWeight: 'bold',
			},
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Button onClick={refreshLog}>
					<CachedIcon />
				</Button>
				<div className={classes.table}>
					<MaterialTable
						title="Logs"
						options={{
							showTitle: false,
							search: true,
							paging: true,
							filtering: false,
							pageSize: 10,
							pageSizeOptions: [],
							showTextRowsSelected: false,
							showSelectAllCheckbox: false,
							fixedColumns: {
								left: 0,
								right: 0,
							},
							rowStyle: {
								backgroundColor: '#EEE',
							},
						}}
						icons={tableIcons}
						columns={columns}
						data={logs.items}
						detailPanel={[
							{
								tooltip: 'Detail',
								width: 1000,
								render: rowData => {
									let mp_data_dump = JSON.parse(rowData.mp_data_dump) || {};

									let conversion_fb_dump =
										JSON.parse(rowData.conversion_fb_dump) || {};

									let conversion_ya_dump =
										JSON.parse(rowData.conversion_ya_dump) || {};

									return (
										<Grid container className={classes.root} spacing={1}>
											{conversion_ya_dump?.offline_conversion_upload?.result ? (
												<Grid item xs={3}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ Передача офлайн-конверсий
																<a
																	target="_blank"
																	href="https://yandex.ru/dev/metrika/ru/management/offline-conv"
																>
																	&nbsp;🔍 подробнее
																</a>
																<br />
															</Typography>
															<Typography variant="body2" component="div">
																<div>
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		ℹ️ Параметры
																		<a
																			target="_blank"
																			href="https://yandex.ru/dev/metrika/ru/management/offline-conv#upload"
																		>
																			&nbsp;🔍 подробнее
																		</a>
																		:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump[
																			'offline_conversion_upload'
																			]['data']
																		}
																	></JSONPretty>
																	<br />
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		Result:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump[
																			'order_statuses_mapping'
																			]['result']
																		}
																	></JSONPretty>
																</div>
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}
											{conversion_ya_dump?.order_statuses_mapping?.result ? (
												<Grid item xs={3}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ Сопоставление статусов заказов
																<a
																	target="_blank"
																	href="https://yandex.ru/dev/metrika/ru/data-import/maporderstatuses-about"
																>
																	&nbsp;🔍 подробнее
																</a>
																<br />
															</Typography>
															<Typography variant="body2" component="div">
																<div>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump[
																			'order_statuses_mapping'
																			]['data']
																		}
																	></JSONPretty>
																	<br />
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		Result:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump[
																			'order_statuses_mapping'
																			]['result']
																		}
																	></JSONPretty>
																</div>
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}
											{conversion_ya_dump?.contacts_upload?.result ? (
												<Grid item xs={3}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ Загрузка данных о клиентах
																<a
																	target="_blank"
																	href="https://yandex.ru/dev/metrika/ru/management/openapi/schema/uploadContactJson_1"
																>
																	&nbsp;🔍 подробнее
																</a>
																<br />
															</Typography>
															<Typography variant="body2" component="div">
																<div>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump['contacts_upload'][
																			'data'
																			]
																		}
																	></JSONPretty>
																	<br />
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		Result:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump['contacts_upload'][
																			'result'
																			]
																		}
																	></JSONPretty>
																</div>
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}
											{conversion_ya_dump?.order_upload?.result ? (
												<Grid item xs={3}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️ Загрузка заказов
																<a
																	target="_blank"
																	href="https://yandex.ru/dev/metrika/ru/management/openapi/schema/uploadOrdersJson_1"
																>
																	&nbsp;🔍 подробнее
																</a>
																<br />
															</Typography>
															<Typography variant="body2" component="div">
																<div>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump['order_upload']['data']
																		}
																	></JSONPretty>
																	<br />
																	<Typography
																		className={classes.cardTitle}
																		color="textSecondary"
																		gutterBottom
																	>
																		Result:
																	</Typography>
																	<JSONPretty
																		id="json-pretty"
																		data={
																			conversion_ya_dump['order_upload'][
																			'result'
																			]
																		}
																	></JSONPretty>
																</div>
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}
											{rowData.error_code ? (
												<Grid item xs={4}>
													<Card className={classes.card}>
														<CardContent>
															<Typography
																className={classes.cardTitle}
																color="textSecondary"
																gutterBottom
															>
																ℹ️
															</Typography>
															<Typography variant="body2" component="div">
																{rowData.error_code in errorTypes
																	? `${rowData.error_code}: ${errorTypes[rowData.error_code]
																	}`
																	: 'error'}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											) : (
												''
											)}
										</Grid>
									);
								},
							},
						]}
						onRowClick={(event, rowData, togglePanel) => togglePanel()}
						isLoading={logs.fetching}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default Logs;
