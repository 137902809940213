const ru = {
	translation: {
		/** Settings and descriptions */
		'Deals Event Settings': 'Привязка целей для сделок',
		'Select the direction (funnel) of deals for setting Google Analytics goals':
			'Выберите направление (воронку) сделок для управления целями Яндекс Метрики.',

		'Leads Event Settings': 'Настройка целей для лидов',
		'Select the direction (funnel) of leads for setting Google Analytics goals':
			'Настройте лиды для управления целями Яндекс Метрики',

		'Client Id field': 'Поле хранения идентификатора ClientID клиента',
		'Client Id field description': `Поле, в котором будет храниться <a href="https://metrika.yandex.ru/support/metrica/data/offline-params.html#offline-params__clientid" target=_blank>ClientID</a>. Оно будет заполнено автоматически, если вы используете скрипт b242ya.js (см. раздел настройки), или вы можете самостоятельно реализовать сохранение этого значения в это поле. ⚡ Должно быть заполнено! ⚡`,

		'User Id field': 'Поле хранения идентификатора User Id',
		'User Id function description': `О функции User-ID <a target="_blank" href="https://support.google.com/analytics/answer/3123662?hl=ru">подробнее</a>`,

		'Cost field': 'Поле хранения значения расходов',
		'Cost field description': `Десятичная дробь. Вы можете передать себестоимость заказов, чтобы учитывать прибыль в отчетах Сквозной аналитики Яндекс Метрики. Прибыль будет рассчитана по формуле: Выручка (сумма сделки) - Себестоимость`,

		'Tid field': 'Поле хранения кода счетчика',
		'Tid field description':
			'Обратите внимание, если в этом параметре выбрано поле и в нем сохранен идентификатор счетчика метрики, то код счетчика, который вы выбираете в настройках направления (в левой части), будет игнорироваться. Это поле используется, когда вы хотите из одного направления Битрикс24 отправлять цели в разные счетчики Яндекс Метрики. Оставьте это поле пустым, если точно не знаете, зачем оно нужно 🤪 и обращайтесь в поддержку на сайте <a href="https://b242ya.ru" target=_blank>b242ya.ru</a> за разъяснением',

		'Custom dimension field': 'Специальные параметры для Сделок',
		'Custom dimension field desctiption': `Для передачи пользовательских свойств в measurement protocol установите соотвествие специальному параметру в Google Analytics 
		<a href="https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters?hl=ru#cd_" target=_blank>
			подробнее
		</a>
			`,

		/** GaCid */
		'Required!': '<b>ОБЯЗАТЕЛЬНО ДЛЯ ЗАПОЛНЕНИЯ!</b>',

		/** CustomDimensions */
		Field: 'Свойство',

		/** Category */
		'Category description': `Укажите аккаунт Яндекс Метрики (счетчик),
	цели (оффлайн конверсии) которого нужно привязать к стадии в левой части. И
	просто перетащите нужную цель на нужную стадию в правой части.
	`,

		'Stage settings description': `Вы можете передать статус стадии в сквозную аналитику Метрики. Для этого укажите один из соответствующих статусов: <b>IN_PROGRESS, PAID, CANCELLED, SPAM</b>
			<a href="https://yandex.ru/support/metrica/crm/about.html#about__analysis" target=_blank>
			подробнее
		</a> <br /> Статус для сквозной аналитики можно передавать без привязки оффлайн-конверсии к стадии, достаточно указать только счетчик в левой части`,

		'Category GA counter substitution warning': `Обратите внимание! В общих настройках указано поле
			хранения кода счетчика - <u><b>{{ gaCounterUserFieldName }}</b></u>. Если оно
			будет иметь значение в карточке CRM, то текущее значение - <u><b>{{ gaCounterInUse}}</b></u>
			будет игнорироваться при отправке данных в Яндекс Мектрику`,

		'Expert mode description': `При активации режима параметры целей для стадий
		можно указывать вручную. <b>ВНИМАНИЕ!</b> В случае
		неправильно указанных параметров цель не будет
		фиксироваться в Google Analytics. Рекомендуется
		выбирать цели непосредственно из списка в левой
		части`,

		'Counter code': 'Код счетчика',
		'expert mode': 'эксперт режим',

		/** GaProfile */
		'All relation between goals and stages will be deleted':
			'Будут удалены все привязанные к стадиям цели. Продолжить?',
		'Google Analytics Account': 'Выбор аккаунта Яндекс Метрики',
		'Select counter': 'Выбор счетчика',
		'Select view': 'Выбор представления',
		'Goals choise': 'Выбор целей',
		'There is no goals in this view': 'Нет целей в этом VIEW',

		'Edit goals in Google Analytics account':
			'редактирование целей в Яндекс Метрике',

		/** GeneralSettings */
		'Js code for placing on your website': 'Код для размещения на сайте',

		'Js code description': `Разместите этот код на всех страницах вашего сайта, тогда
		Яндекс ClientId, будет
		автоматически добавлен в результат CRM форм. Также ClientId будет
		добавлен в лиды/сделки, созданные из всех каналов виджета
		(Битрикс24 чат, Whatsapp, Telegram и т.д).`,

		'External auth': 'Внешние авторизации',
		'Auth description': `Управление авторизациями интегрированных сервисов`,
		'GA counter by default': 'Код счетчика по умолчанию',

		'GA counter by default description': `Для поддержки нескольких сайтов-источников код счетчика может
		быть передан из разных полей, в зависимости от того, какое из
		них заполнено. <br /> <br />
		Код передается, если :<br />
		1) Есть значение в поле "код счетчика" сделки или лида
		<br />
		2) Есть значение в поле "код счетчика" в настройках направления
		И не заполнено поле из п.1
		<br />
		<br />
		При пустых значениях в п.1 и п.2, будет передано значение из
		поля ниже.`,
		'Track id title': 'Управление <b>TRACK ID</b> в карточке crm лида и сделки',
		'Track id description settings': `При включении опции в карточке лида и сделки появится вкладка
		B242YA. 🙄 Но
		<a target="_blank" href="https://b242ga.ru/blog/track_id">
			что это?
		</a>`,
		'Placement log title': 'Лог передачи событий в Яндекс.Метрику',
		'Placement log': 'Лог событий в карточке CRM',
		'Placement log description':
			'При включении опции в карточке CRM станет доступна вкладка <b>B242YA</b> с логом событий по сделке/лиду, которые были переданы в Яндекс.Метрику',

		/** Auths */
		'The Google Analytics authorization will be removed': `Будет удалена авторизация Google Analytics. Все настройки приложения, все потоки выгрузки!!`,
		'Delete GA auth': `Удалить GA авторизацию`,
		'The Facebook authorization will be removed': `Будет удалена авторизация Facebook. Все потоки выгрузки, связанные с Facebook будут удалены!`,
		'Delete FB auth': `Удалить FB авторизацию`,
		'Delete VK auth': `Удалить VK авторизацию`,
		'The Vk authorization will be removed': `Будет удалена авторизация Vk. После нажатия кнопки "Да" все потоки выгрузки, связанные с Vk будут удалены! Vk API не поддерживает метод отзыва доступа, для удаления доступа B242GA к вашему аккаунту перейдите по ссылке <a href="https://vk.com/settings?act=apps" target="_blank">удалить доступ B242GA</a>`,
		'The Yandex authorization will be removed': `Будет удалена авторизация Yandex. Для полного удаления доступа B242GA к вашему аккаунту перейдите страницу Управление аккаунтом -> Входы и устройства -> Устройства, сервисы и программы. <a href="https://passport.yandex.ru/profile" target="_blank">Управление аккаунтом на passport.yandex.ru</a>`,
		/** Costs*/
		'Costs settings title': 'Выгрузка расходов в Яндекс Метрику',
		'Costs settings description': `Выберите источник расходов для создания потока выгрузки: 
`,
		'ADS sources': 'Управление потоками',

		/** CostSettings Form */
		'Create Data Set': ` <a href="{{ link }}" target =_blank > Create Data Set</a> <br /><br />
		Пожалуйста, обратите внимание, при создании новой схемы, поля, указанные ниже, обязательны:<br />
		<ul><li>ga:date</li><li>ga:medium</li><li>ga:source</li><li>ga:adClicks</li><li>ga:impressions</li><li>ga:adCost</li><li>ga:campaign</li><li>ga:keyword</li><li>ga:adContent</li></ul>
		`,
		'Create Data Set Example': ` <a href = "{{ link }}" target=_blank> Create Data Set Example</a> `,
		'Data set required fileds': `Пожалуйста, убедитесь, что выбранная схема содержит все указанные поля:<br />
		<ul><li>ga:date</li><li>ga:medium</li><li>ga:source</li><li>ga:adClicks</li><li>ga:impressions</li><li>ga:adCost</li><li>ga:campaign</li><li>ga:keyword</li><li>ga:adContent</li></ul>`,
		'Mapping Google Analytics API Fields':
			'Соответствие полей Google Analytics Schema ⬅️ <a target="_blank" href="{{link}}">{{source}}</a>',

		'Mapping Yandex Metrica API Fields':
			'Соответствие полей Yandex Metrica Schema ⬅️ <a target="_blank" href="{{link}}">{{source}}</a>',

		'Utm Settings Facebook':
			'Значения для utm меток в настройках объявления в Facebook',
		'Utm Settings Vk':
			'Метки в настройках объявления Vk ⬅️ <a target="_blank" href="{{link}}">{{source}}</a>',
		'Utm Settings Yandex.Direct':
			'Значения для utm меток в настройках объявления в Yandex.Direct',

		'There is no Data set': `Для указанного счетчика нет Data Set.<br />
	Пожалуйста, создайте Data Set(ссылки ниже) <br /> и обновите данные этого шага настройки.`,

		'Data set has no view link': `Указанный Data Set не привязан ни к одному View, <br /> пожалуйста, сделайте это, это обязательно`,
		'Bind Data Set to the desired View': `Привязать Data Set к нужному View`,
		'Select GA account': `Выбор аккаунта Google Analytics`,
		'Select YA account': `Выбор счетчика Яндекс Метрики`,
		'Select VK account': `Выбор VK аккаунта`,
		'Google Analytics account': `Аккаунт Google Analytics`,
		'Select counter': `Выбор счетчика`,
		'GA counter': `Счетчик Google Analytics`,
		'Data set for costs upload': `Data Set Google Analytics для загрузки расходов`,
		'Everything is configured, data will be loaded within 24 hours.': `Все настроено, данные будут загружаться в течении суток.`,
		'Google Analytics. Check this out': `GOOGLE ANALYTICS. ОБЯЗАТЕЛЬНО УБЕДИТЕСЬ: `,
		'Import Behavior should be equal Owerwrite': `Import Behavior равен Owerwrite`,
		'Costs will be overwritten': `Расходы будут перезаписываться при каждом новом импорте`,
		'Mandatory Data Set parameters are specified': `Обязательные параметры Data Set указаны`,
		'Set utm tags': `Обязательно указывайте в схеме Data Set следующие поля < br /> <b> ga:date ga:medium ga:source ga:adClicks ga:adCost ga:impressions ga:campaign</b>`,
		'You can edit the current Data Set by following the link': `Отредактировать текущий Data Set можно по ссылке`,
		'Facebook ads. Check this out': `FACEBOOK ADS. ОБЯЗАТЕЛЬНО УБЕДИТЕСЬ`,

		'Use next params': `При создании рекламного объявления используйте как минимум < br />
	следующие параметры, иначе данные по расходам будут неточны: `,

		/** Datalens */
		/** Generator */
		'Datalens setting': `Настройка выгрузки данных в Yandex Datalens`,
		'Datalens source setting': `Настройка utm меток справочника источников`,
		'Datalens source settings description': `Описание сопоставления справочника источнков Битрикс24 с utm метками для формирования отчетов`,

		'Generator caution': `<b> ВНИМАНИЕ!</b > Крайне рекомендуется при использовании этой
функции ставить цель на стадию следующую за стадией по умолчанию, в
которой создается лид / сделка.В случае, если у вас CID и UTM метки
добавляются ПОСЛЕ создания лида / сделки, такой подход позволит
избежать отправки сгенерированных, а не реальных CID и UTM - генерация сработает только если у стадии есть привязанная цель в GA.
		<br/> <br />
Что будет происходить при включении:
		<br />
		<br />
		1️⃣  Если у лида / сделки не определен CID, он будет сгенерирован и
сохранен в CRM
	<br /> <br />
	2️⃣  Если у лида / сделки не определен UTM_SOURCE, но есть источник из
справочника Битрикс24, то будут установлены utm метки из
соответсивия по списку справа
	<br /> <br />
	3️⃣ Если у лида / сделки нет источника или для источника в списке не
установлены метки, автоматически будет установлены метки из
источника B242GA_NO_SOURCE(см.последний пункт в списке ниже).`,

		/** Logs */
		ERROR_REDIS: 'Внутренняя ошибка сервиса. ERROR_REDIS',
		ERROR_MP_PARAMS: 'ERROR_MP_PARAMS',
		WARNING_YA_COUNTER_NOT_FOUND:
			'Укажите в настройках счетчик для этой воронки',
		WARNING_MP_PARAMS_NO_CLA:
			'У стадии нет привязанной цели, данные не переданы в GA. WARNING_MP_PARAMS_NO_CLA',
		ERROR_MP_PARAMS_CID_REQUIRED:
			'Требуется Google Analytics ClientId. ERROR_MP_PARAMS_CID_REQUIRED',
		ERROR_MP_PARAMS_TID_REQUIRED:
			'Требуется код счетчика. ERROR_MP_PARAMS_TID_REQUIRED',
		ERROR_MP_PARAMS_CID_WRONG_FORMAT: 'ERROR_MP_PARAMS_CID_WRONG_FORMAT',
		ERROR_API_DB: 'Внутренняя ошибка сервиса. ERROR_API_DB',
		ERROR_B24_CALL: 'Внутренняя ошибка сервиса. ERROR_B24_CALL',
		ERROR_GET_SETTINGS: 'Внутренняя ошибка сервиса. ERROR_GET_SETTINGS',
		ERROR_SEND_TO_GA:
			'Ошибка отправки данных в Google Analytics. ERROR_SEND_TO_GA',
		ERROR_TRACKER_DATA: 'Внутренняя ошибка сервиса. ERROR_TRACKER_DATA',

		/** CrmDetailTabs */
		'Track id description': `Попросите звонящего нажать Esc и продиктовать вам номер.Введите
его в поле Track id.Как это работает
	<a href="https://b242ga.ru/blog/track_id" target="_blank">
		подробнее
		</a > `,

		/** Email tracking */
		'Add new email': 'Новая запись:',
		'List of emails': 'Список email-ов:',
		'Email tracking description': `<b>Как работает динамический email tracking</b>. <br />
			<p> ✔️ Разместите скрипт b242ya.js из раздела настроек на странице сайта</p>
			<p> ✔️ В блоке справа добавьте пару <b>имя класса контейнера</b> - <b>email</b>.<br />
			<b>Имя класса контейнера</b> - это значение для атрибута class HTML-тега, в который будет подставляться <b>email</b>.
			Например, если имя класса контейнера email-1, то на странице нужно разместить span class="email-1" или div class="email-1".
			В поле <b>email</b> справа ⚡ обязательно ⚡ укажите <b>#ID#</b> для динамической подстановки TRACK ID в адрес почтового ящика.
			Например, order+#ID#@b242ya.ru.<br /><br />В результате, на странице где размещен скрипт b242ya.js и теги с классом  span class="email-1" или div class="email-1",
			будет показан <b>email</b> order+12345@b242ya.ru где 12345 - идентификатор TRACK ID, по которому приложение B242YA получит все необходимые данные о пользователе и добавит их в CRM </p>
			<p> ✔️ Для обработка email с получателем order+12345@b242ya.ru ваш почтовый сервер должен поддерживать плюс-адресацию, чтобы, например, письмо на order+23123@b242ya.ru
			было направлен на ящик order@b242ya.ru. Многие сервисы поддерживают такой функционал. Например, GMAIL - дополнительно ничего настраивать не нужно</p>
			<p> ✔️ 🔗<a href="https://helpdesk.bitrix24.ru/open/8233895/" target=_blank>К Битрикс24 подключите существующий ящик</a> ,
			на который будет приходить письма, по первому или второму варианту, несуществующему адресату order+12345@b242ya.ru или, соответственно, в результате плюс-адресации на order@b242ya.ru</p>
			<p> ✔️ Лид или сделка, которые будут созданы по письму на настроенный адрес, будут содержать <a href="https://metrika.yandex.ru/support/metrica/data/offline-params.html#offline-params__clientid" target=_blank>ClientID</a> - об этом позаботится 🧚🧚<b>B242YA</b>🧚🧚</p> `,
		'Class name warning epmty': 'введите имя класса',
		'Email track empty': 'введите email ​',
		'Email track need ID':
			'email дожен содержать #ID#, например, order+#ID#@b242ya.ru',

		/** Call tracking */
		'Add new phone': 'Новая запись:',
		'List of phones': 'Список телефонных номеров:',
		'Call tracking description': `
			<b>Как настроить динамический call tracking</b>. <br /><br />
			<b>Базовые настройки:</b>					
			<p>✔️ Разместите скрипт b242ya.js из раздела "настройки" на странице сайта</p>
			<p>✔️ 🔗 <a href="https://helpdesk.bitrix24.ru/open/19732572/" target=_blank>Подключите пакет телефонных номеров к вашему порталу.</a><br /> 
			Чем больше номеров в пакете, тем ниже будет погрешность в определении данных трекинга.</p> 					
			<p>✔️ В блоке справа  в полях “Phone” добавьте список телефонных номеров из пакета или пакетов.</p> 					
			<p>✔️ На страницах сайта, в блоках с номером телефона в class элементов, в которых размещен телефонный номер, добавьте b242ya-call-tracker. 
			Например, если телефон на странице размещается в теге div class="phone", то для динамической подстановки добавьте в класс  div class="phone b242ya-call-tracker".</p> 

			<b>Группировка номеров в пулы:</b><br />
			<p>Если вы используете отдельные пакеты номеров для нескольких сайтов, например, когда у вас система региональных сайтов и в каждом нужно показывать номера с определенным кодом города, то необходимо разбить список номеров на соответствующие региону пулы:</p>
			<p>✔️ Сгруппировать список номеров в пулы для отображения на определенном сайте, указав идентификатор в поле “Pool Name”. В названии можно использовать цифры от 0 до 9, латинские буквы и знаки "-" "_".</p>
			<p>✔️ Для ротации номеров из пула на соответствующем сайте, необходимо указать идентификатор пула в параметрах скрипта b242ya.js, размещенного на страницах сайта -
			<b>presets:{CALL_TRACKING_POOL_NAME:'идентификатор'</b>}
			🔗 <a href="https://b242ya.ru/features/call-tracking" target=_blank>Подробная информация о настройке calltracking B242YA</a>
			</p>				
			<p>✔️ Лид или сделка, которые будут созданы по звонку на номер из списка справа, будут содержать ClientID - об этом позаботится 🧚🧚<b>B242YA</b>🧚🧚
		`,
		'Phone track empty': 'введите номер телефона ​',
		'Phone track error': 'введите номер в корректном формате​',
		'Phone track pool name empty': 'введите имя пула',
		'Phone track pool name error':
			'допустимые значения: цифры 0-9, латинские буквы и знаки "-", "_"',

		/** Menu items */
		'M.DEALS': 'Сделки',
		'M.LEADS': 'Лиды',
		'M.SETTINGS': 'Настройки',
		'M.GENERATOR': 'Генератор CID',
		'M.LOGS': 'Логи',
		'M.COSTS': 'Расходы',
		'M.EMAIL-T': 'Email Tracking',
		'M.CALL-T': 'Call Tracking',
		'M.DATALENS': 'DataLens (soon)',

		/** Common */
		Save: 'Сохранить',
		Yes: 'Да',
		Cancel: 'Отмена',
		'Attention!': 'Внимание!',
		Login: `Авторизоваться`,
		'turn on': 'включить',
		'turn off': 'выключить',
	},
};

export default ru;
