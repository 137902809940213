export const GA_ACCOUNT_SUMMARIES_FETCH_START = 'GA_ACCOUNT_SUMMARIES_FETCH_START';
export const GA_ACCOUNT_SUMMARIES_FETCH_SUCCESS = 'GA_ACCOUNT_SUMMARIES_FETCH_SUCCESS';
export const GA_ACCOUNT_SUMMARIES_FETCH_ERROR = 'GA_ACCOUNT_SUMMARIES_FETCH_ERROR';

export const gaAccountSummariesFetchStart = (payload) => {
    return {
        type: GA_ACCOUNT_SUMMARIES_FETCH_START,
        payload
    }
}

export const gaAccountSummariesFetchSuccess = (payload) => {
    return {
        type: GA_ACCOUNT_SUMMARIES_FETCH_SUCCESS,
        payload
    }
}

export const gaAccountSummariesFetchError = (payload) => {
    return {
        type: GA_ACCOUNT_SUMMARIES_FETCH_ERROR,
        payload
    }
}

export const GA_CUSTOM_DATA_SOURCES_FETCH_START = 'GA_CUSTOM_DATA_SOURCES_FETCH_START';
export const GA_CUSTOM_DATA_SOURCES_FETCH_SUCCESS = 'GA_CUSTOM_DATA_SOURCES_FETCH_SUCCESS';
export const GA_CUSTOM_DATA_SOURCES_FETCH_ERROR = 'GA_CUSTOM_DATA_SOURCES_FETCH_ERROR';

export const gaCustomDataSourcesFetchStart = (payload) => {
    return {
        type: GA_CUSTOM_DATA_SOURCES_FETCH_START,
        payload
    }
}

export const gaCustomDataSourcesFetchSuccess = (payload) => {
    return {
        type: GA_CUSTOM_DATA_SOURCES_FETCH_SUCCESS,
        payload
    }
}

export const gaCustomDataSourcesFetchError = (payload) => {
    return {
        type: GA_CUSTOM_DATA_SOURCES_FETCH_ERROR,
        payload
    }
}

export const GA_GOALS_LIST_FETCH_START = 'GA_GOALS_LIST_FETCH_START';
export const GA_GOALS_LIST_FETCH_SUCCESS = 'GA_GOALS_LIST_FETCH_SUCCESS';
export const GA_GOALS_LIST_FETCH_ERROR = 'GA_GOALS_LIST_FETCH_ERROR';

export const gaGoalsListFetchStart = (payload) => {
    return {
        type: GA_GOALS_LIST_FETCH_START,
        payload
    }
}

export const gaGoalsListFetchSuccess = (payload) => {
    return {
        type: GA_GOALS_LIST_FETCH_SUCCESS,
        payload
    }
}

export const gaGoalsListFetchError = (payload) => {
    return {
        type: GA_GOALS_LIST_FETCH_ERROR,
        payload
    }
}