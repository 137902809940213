import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
	table: {
		minWidth: 250,
	},
});

function createData(utm, param) {
	return { utm, param };
}

const utmSettings = [
	createData('utm_source', 'vk'),
	createData('utm_medium', 'cpc'),
	createData('utm_campaign', '{campaign_name}'),
	createData('utm_content', 'название объявления'),
];

export default function UtmSettings() {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell align="left">
							<b>UTM</b>
						</TableCell>
						<TableCell align="left">
							<b>Parameter</b>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{utmSettings.map(row => (
						<TableRow key={row.utm}>
							<TableCell align="left">{row.utm}</TableCell>
							<TableCell align="left">{row.param}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
